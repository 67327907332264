<div class="side-bar left-bar margin-top-70 ">
  <div class="nicescroll">
      <ul class="nav nav-tabs tabs">
          <li class="active tab width-100">
              <a href="#taskprofile" data-toggle="tab" aria-expanded="false">
                  <em class="fa fa-angle-left fa-2x fastyle left-bar-toggle waves-effect waves-light"></em>
              </a>
          </li>
      </ul>
      <div class="tab-content">
          <div id="taskprofile" class="tab-pane fade in active">
              <div class="row text-center">
                  <!--image form-->
                  <form class="form-horizontal" role="form" [formGroup]="UserForm" #userForm="ngForm">
                      <div *ngIf="this.usertype==='lab'">
                          <input type="hidden" formControlName="_id" [(ngModel)]="lab._id">
                      </div>

                      <div *ngIf="this.usertype==='clinic'">
                          <input type="hidden" formControlName="_id" [(ngModel)]="clinic._id">
                      </div>

                      <div id="avatar" class="avatar">
                          <p class="avatar-para" *ngIf="!user.logoPath && !localImageUrl"
                              [attr.data-letters]="getFirstTwoLetters(user.name)"></p>

                          <div *ngIf="!localImageUrl && user.logoPath" id="preview2">
                              <img [src]="sanitizer.bypassSecurityTrustResourceUrl(user.logoPath)" id="avatar-image"
                                  class="avatar_img" alt="avatar-image-1">
                          </div>
                          <div *ngIf="localImageUrl" id="preview">
                              <img [src]="localImageUrl" id="avatar-image" class="avatar_img" alt="avatar-image-1">
                          </div>
                          <div class="avatar_upload">
                              <label class="upload_label">
                                  <em class="md md-camera-alt"></em>
                                  <input (click)="fileInput2.click()" class="upload" id="upload">
                              </label>

                              <button *ngIf="user.logoPath" class="btn btn-light inline-btn margin-r-2x"
                                  (click)="removeImage(user)">
                                  <em class="fa fa-times" aria-hidden="true"></em>
                              </button>
                          </div>
                      </div>
                      <div class="nickname">
                          <span id="name" tabindex="4" data-key="1" contenteditable="true"
                              onkeyup="changeAvatarName(event, this.dataset.key, this.textContent)"
                              onblur="changeAvatarName('blur', this.dataset.key, this.textContent)"></span>
                      </div>
                      <span
                          class="nick-name-plus">
                          <input type="file" accept=".jpg,.png" (change)="onFileChange($event, user.logopath)"
                              formControlName="logoPath" #fileInput2 ng2FileSelect [uploader]="uploader" />
                      </span>
                      <div *ngIf="_anotherUpload == true" class="inline-btn">
                          <button class="btn btn-light margin-r-2x" (click)="onSave(userForm, user.logopath)">
                              <em class="fa fa-check" aria-hidden="true"></em>
                          </button>

                          <button class="btn btn-light margin-r-2x" (click)="exitUpdate()">
                              <em class="fa fa-undo" aria-hidden="true"></em>
                          </button>
                      </div>
                  </form>
                  <!--exit image form-->
              </div>
              <div class="row">
                  <div class="col-sm-12 text-center m-t-15 left-bar-details m-b-10">
                      <h4 class="text-primary m-0">{{user.name}}</h4>
                      <small class="text-info">{{user.city}}</small>
                  </div>
              </div>
              <div id="breakpoints" class="m-b-5"></div>
              <div class="row l-h-2">
                  <div class="wrp-row">
                      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                          <em class="fa fa-map-marker m-l-5" aria-hidden="true">
                          </em> {{ 'resource.address' | translate }} </div>
                      <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                          <div class="wid-coll t-a-r">{{user.address}}</div>
                          <div class="wid-coll t-a-r">{{user.postalCode}} {{ (user.townName) ? user.townName : ''}}</div>
                          <div class="wid-coll t-a-r">{{user.provinceName}}</div>
                      </div>
                  </div>

                  <div class="wrp-row">
                      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                          <em class="fa fa-envelope-o m-l-5" aria-hidden="true"></em> {{ 'resource.email' | translate }}
                      </div>
                      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 wid-coll t-a-r email-detail" data-placement="bottom" title="{{user.email}}">{{user.email}}</div>
                  </div>

                  <div class="wrp-row">
                      <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                          <em class="fa fa-mobile m-l-5" aria-hidden="true"></em> {{ 'resource.contact' | translate }}
                      </div>
                      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 wid-coll t-a-r">{{user.telephoneNumber}}</div>
                  </div>

                  <div class="wrp-row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" *ngIf="usertype === 'clinic'">
                          <em class="fa fa-medkit m-l-5" aria-hidden="true"></em> {{ 'resource.clinicCode' | translate }}
                      </div>
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll wid-coll2 t-a-r">{{user.clinicCode}}</div>
                  </div>

                  <div class="wrp-row">
                      <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8" *ngIf="usertype === 'lab'">
                          <em class="fa fa-flask m-l-5" aria-hidden="true"></em> {{ 'resource.labCode' | translate }} </div>
                      <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 wid-coll t-a-r">{{user.labCode}}</div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row wrapper-md leftbar-app-config-section">
          <div class="col-sm-12 m-t-10">
              <select class="form-control" [(ngModel)]="user.language" (change)="changeLanguage(user.language)">
                  <option value='en'>{{ 'resource.english' | translate }}</option>
                  <option value='es'>{{ 'resource.spanish' | translate }}</option>
              </select>
          </div>
          <br>
          <div class="col-sm-8 m-t-20" *ngIf="usertype == 'clinic'">
              <p>{{ 'resource.hidePatientInfo' | translate }}</p>
          </div>
          <div class="col-sm-4 t-a-r m-t-20" *ngIf="usertype == 'clinic'">
              <button *ngIf="user.isHidePatientInfo === true" type="button" class="btn btn-toggle active"
                  data-toggle="button" (click)="changePatientPrivacy(false)" aria-pressed="true" autocomplete="off">
                  <div class="handle"></div>
              </button>
              <button *ngIf="user.isHidePatientInfo !== true" type="button" class="btn btn-toggle" 
                  data-toggle="button" (click)="changePatientPrivacy(true)" aria-pressed="true" autocomplete="off">
                  <div class="handle"></div>
              </button>
          </div>
          <br>
          <div class="col-sm-8 m-t-20" *ngIf="usertype == 'clinic' && ('jobEditDeletePermission' | featureFlag)" >
              <p>{{ 'resource.job_edit_permission' | translate }}</p>
          </div>
          <div class="col-sm-4 t-a-r m-t-20" *ngIf="usertype == 'clinic' && ('jobEditDeletePermission' | featureFlag)">
              <button type="button" class="btn btn-toggle active" *ngIf="user.isEnableJobEdit === true"
                  data-toggle="button" (click)="changeJobEditPermission(false)" aria-pressed="true" autocomplete="off">
                  <div class="handle"></div>
              </button>
              <button type="button" class="btn btn-toggle" *ngIf="user.isEnableJobEdit !== true"
                  data-toggle="button" (click)="changeJobEditPermission(true)" aria-pressed="true" autocomplete="off">
                  <div class="handle"></div>
              </button>
          </div>
          <br>
          <div class="col-sm-8 m-t-20" *ngIf="usertype == 'clinic' && ('jobEditDeletePermission' | featureFlag)">
              <p>{{ 'resource.job_delete_permission' | translate }}</p>
          </div>
          <div class="col-sm-4 t-a-r m-t-20" *ngIf="usertype == 'clinic' && ('jobEditDeletePermission' | featureFlag)">
              <button type="button" class="btn btn-toggle active" *ngIf="user.isEnableJobDelete === true"
                  data-toggle="button" (click)="changeJobDeletePermission(false)" aria-pressed="true" autocomplete="off">
                  <div class="handle"></div>
              </button>
              <button type="button" class="btn btn-toggle" *ngIf="user.isEnableJobDelete !== true"
                  data-toggle="button" (click)="changeJobDeletePermission(true)" aria-pressed="true" autocomplete="off">
                  <div class="handle"></div>
              </button>
          </div>
      </div>
  </div>

</div>

<div class="wrapper fadeInDown d-flex justify-content-center w-100">
  <div class="logo">
     <img src="../../../assets/images/dentbox_logo.png" alt="Dentbox">
  </div>
  <div id="formContent" class="p-0">
     <div class="fadeIn first">
        <h3>{{ 'authenticate.login' | translate }}</h3>
     </div>

     <form autocomplete="off" [formGroup]="formGroup">
        <div class="form-group">
           <label>{{ 'authenticate.username' | translate }}</label>
           <input id="username" type="text" class="fadeIn second" autocomplete="off"
              placeholder="{{ 'authenticate.username' | translate }}"  formControlName="username">
           <div class="error error-signin text-left" *ngIf="formGroup.controls.username.dirty && formGroup.controls.username?.errors?.required">
              {{ 'common.required' | translate }} 
           </div>
        </div>
        <div class="form-group">
           <label>{{ 'authenticate.password' | translate }}</label>
           <input id="password" class="fadeIn third" autocomplete="off" class="form-control" type="password" style="-webkit-text-security:disc"
              placeholder="{{ 'authenticate.password' | translate }}"  formControlName="password"
              (keyup.enter)="onClickSignIn()">
        </div>
        <button (click)="onClickSignIn()" class="fadeIn fourth login" type="button">{{ 'authenticate.login' | translate }}
        </button>
     </form>

     <div id="formFooter">
        <a [routerLink]="['/forgot']">{{ 'authenticate.forgot_password' | translate }}</a>
     </div>
  </div>
</div>
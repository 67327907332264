import { ClinicService } from 'src/app/service/clinic.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ChatService } from '../../../service/chat.service';
import { Work } from '../../../model/work';
import { of, Subscription } from 'rxjs';
import { Message } from '../../../model/message';
import { SocketService } from '../../../service/sockets.service';
import { Attachment } from '../../../model/attachment';
import { WorkService } from '../../../service/work.service';
import { ToastrService } from 'ngx-toastr';
import { Clinic } from '../../../model/clinic';
import { ApiConnection } from '../../../service/apiConnection';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddWorkComponent } from '../add-work/add-work.component';
import { LanguageService } from 'src/app/service/language.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'src/app/service/message.service';
import { delay, switchMap } from 'rxjs/operators';
import { FeatureFlagService } from 'src/app/service/feature-flag.service';

@Component({
  selector: 'chat-rightbar',
  providers: [],
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.scss']
})
export class RightbarComponent implements OnInit, OnDestroy {

  @Input() providedWork: Work;
  FILE_SERVER_URL = ApiConnection.FILE_SERVER_URL + '/';

  constructor(private _chatService: ChatService, private _socketService: SocketService, private _clinicService: ClinicService, 
    private modalService: NgbModal, private _toastrService: ToastrService, private _uiLoaderService: NgxUiLoaderService, 
    private _languageService: LanguageService, private _workService: WorkService, private _messageService: MessageService,
    private featureFlagService: FeatureFlagService) { }

  private subscriber: Subscription;

  public user_type: string;
  public user_id: string;
  public attachmentList: Attachment[] = [];
  public work: Work;
  public messageList: Message[] = [];
  public imageThumbnails: any;
  public documentThumbnails: any;
  public statusList: string[] = ['pending', 'active', 'rejected', 'closed'];
  public isHidePatient: boolean;
  public clinicCode: string;
  showAllAttachments = false;

  isEnableJobEdit: boolean = false;
  isEnableJobDelete: boolean = false;

  ngOnInit() {
    this.isEnableJobEdit = false;
    this.isEnableJobDelete = false;
    this.showAllAttachments = false;
    this.user_type = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    this.user_id = sessionStorage.getItem('4CDC84B1E0607D91E3C7BBDE07EECC6A');

    this._workService.workViewSubject.subscribe((res: any) => {
      this.work = res;
    })

    this.subscriber = this._chatService.currentSelectedWork.subscribe(
      data => {
        this.work = data;
        this.showAllAttachments = false;

        this._messageService.getAllAttachmentsByWorkId(this.work._id).subscribe(
          response => {
            this.attachmentList = response.attachments;
          },
          error => {
            this.attachmentList = [];
            console.error('Error fetching attachments:', error);
          }
        );

        if (data.clinicId !== undefined) {
          this._clinicService.getClinicById(data.clinicId).subscribe(((clinic: Clinic) => {
            this.isHidePatient = clinic.isHidePatientInfo;

            if (this.featureFlagService.isFeatureEnabled('jobEditDeletePermission')) {
              this.isEnableJobEdit = clinic.isEnableJobEdit;
              this.isEnableJobDelete = clinic.isEnableJobDelete;
            }
            
          }));
        }
      });
      
      // get all attachments for work right panel
      this._chatService.uploadAttachment.subscribe((res: any) => {
        this._messageService.getAllAttachmentsByWorkId(this.work._id).pipe(
          switchMap(response => 
            of(response).pipe(delay(2000)) // Delay of 1 second before emitting the response
          )
        ).subscribe(
          response => {
            this.attachmentList = response.attachments;
          },
          error => {
            this.attachmentList = [];
            console.error('Error fetching attachments:', error);
          }
        );

      });
    this.subscriber = this._chatService.currentMessageList.subscribe(list => this.messageList = list);
    this.clinicCode = JSON.parse(window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9')).clinicCode;

    this.imageThumbnails = this._chatService.ResourcePath.IMAGE_THUMBNAILS;
    this.documentThumbnails = this._chatService.ResourcePath.DOCUMENT_THUMBNAILS;

    // this._socketService.getAttachmentsByWorkIdReciever()
    //   .subscribe(
    //     res => { this.attachmentList = res; },
    //     err => console.log(err)
    //   );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.showAllAttachments = false;
  }

  async showProfile(): Promise<void> { }

  public getFirstTwoLetters(patientName: string) {
    if (patientName) {
      const arr = patientName.split(' ');
      if (arr.length > 1) {
        return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
      } else {
        return (arr[0].substring(0, 2)).toUpperCase();
      }
    }
  }

  public onOpenEditWorkModal(work: Work) {
    const modalRef = this.modalService
      .open(AddWorkComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'add-work-popup-wrapper'
      });
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.work = work;
  }

  public onDeleteWork(work: Work) {
    this._chatService.deleteWork(work)
      .subscribe(
        async res => {
          if (res.isSuccess) {
            const successMsge = await this._languageService.getTranslation('common.delete_success');
            this._toastrService.success(successMsge);
            const elementId = document.getElementById('wrapper');
            if (elementId) {
              elementId.classList.remove('right-bar-enabled')
            }
            this.work = new Work();
            this._workService.workSubject.next(null);
          }
        },
        async err => {
          console.log(err);
          const errorMsge = await this._languageService.getTranslation('common.delete_error');
          this._toastrService.error(errorMsge);
          this._uiLoaderService.stop();
        },
        () => {
          this._uiLoaderService.stop();
        }
      );
  }

  isImage(item: any): boolean {
    return ['.png', '.jpg', '.jpeg', '.gif'].includes(item.extension);
  }

  isDocument(item: any): boolean {
    return ['.txt', '.docx', '.pdf', '.xlsx', '.xls', '.doc'].includes(item.extension);
  }

  isVideo(item: any): boolean {
    return ['.mp4', '.mov'].includes(item.extension);
  }

  getFileUrl(item: any): string {
    return `${this.FILE_SERVER_URL}${this.work._id}/${item.url}`;
  }

  getThumbnailUrl(item: any): string {
    return item.thumbnailUrl ? `${this.FILE_SERVER_URL}${this.work._id}/${item.thumbnailUrl}` : this.documentThumbnails;
  }

  toggleAttachments() {
    this.showAllAttachments = !this.showAllAttachments;
  }

}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { USERS } from 'src/app/common/endpoints';
import { RecoverPasswordViewModel } from 'src/app/model/recover-password-view-model';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/service/language.service';
import { ShowInvalidFormControls } from 'src/app/service/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  rpvm: RecoverPasswordViewModel;
  resetFormGroup: UntypedFormGroup;
  resetForm: NgForm;

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    private _uiLoaderService: NgxUiLoaderService,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _languageService: LanguageService)
  {
    this.rpvm = new RecoverPasswordViewModel();
    this._route.queryParams.subscribe((params: Params) => {
      this.rpvm.email = params["email"];
      
      if (this.rpvm.email == undefined) {
        this._router.navigate(['/dsa-login']);
      }
      
    });
  }

  ngOnInit() {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.resetFormGroup = this._fb.group({
      token: ['', [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
      password: ['', [ Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(nonWhitespaceRegExp)]]
    }, { validator: [ResetPasswordComponent.MatchPassword] });


  }
  
  // tslint:disable-next-line: member-ordering
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      return { MatchPassword: true };
    } else {
      return null;
    }
  }

  async resetPassword(form: NgForm, event: any) {
    if (this.resetFormGroup.invalid) { ShowInvalidFormControls(this.resetFormGroup); return; }
    if (this.rpvm.password !== undefined && this.rpvm.token && event) {
      try {
        this._uiLoaderService.start();
        const user = new User();
        user.email = this.rpvm.email;
        user.password = btoa(this.rpvm.password);
        user.resetToken = this.rpvm.token;
        const response = await this._authService.post(USERS.RESET, user).toPromise();
        
        if (response && response.body) {
          if (response.body.status === 200) {
            const toastMsge = await this._languageService.getTranslation('authenticate.password_has_been_changed');
            this._toastrService.success(toastMsge);
            setTimeout(() => {
              this._router.navigate(['/dsa-login']);
            }, 3500)

          }
          else if (response.body.status === 400) {
            const toastMsge = await this._languageService.getTranslation('authenticate.password_reset_failed');
            this._toastrService.error(toastMsge);
          }
          else if (response.body.status === 406) {
            const toastMsge = await this._languageService.getTranslation('authenticate.validations.invalid_verification_code');
            this._toastrService.error(toastMsge);

            this.resetFormGroup.setErrors({ invalidVerificationCode: true });
            const tokenControl = this.resetFormGroup.get('token');
            tokenControl.markAsDirty({ onlySelf: true });
            tokenControl.markAsTouched({ onlySelf: true });
            this._uiLoaderService.stop();
            return;
          }
          else if (response.body.status === 407) {
            const toastMsge = await this._languageService.getTranslation('authenticate.validations.email_invalid');
            this._toastrService.error(toastMsge);
          }
          else {
            const toastMsge = await this._languageService.getTranslation('authenticate.password_reset_failed');
            this._toastrService.error(toastMsge);
          }
        }
        else {
          const toastMsge = await this._languageService.getTranslation('authenticate.password_reset_failed');
          this._toastrService.error(toastMsge);
        }
      } catch (error) {
        const toastMsge = await this._languageService.getTranslation('authenticate.password_reset_failed');
        this._toastrService.error(toastMsge);
      } finally {
        this._uiLoaderService.stop();
      }
    }
  }
  
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginationResponse } from 'src/app/common/pagination';
import { Clinic } from 'src/app/model/clinic';
import { DentboxWorkListFilterModel } from 'src/app/model/dentbox-work-list-filter';
import { Lab } from 'src/app/model/lab';
import { ApiConnection } from 'src/app/service/apiConnection';
import { ChatService } from 'src/app/service/chat.service';
import { ExpandViewService } from 'src/app/service/expand-view.service';
import { UploadService } from 'src/app/service/upload.service';
import { saveAs } from 'file-saver';
import { ProfileService } from 'src/app/service/profile.service';
import { WorkListProfile } from 'src/app/model/work-list-profile';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/service/language.service';
import { FeatureFlagService } from 'src/app/service/feature-flag.service';

@Component({
  selector: 'app-dentbox-work-request-list',
  templateUrl: './dentbox-work-request-list.component.html',
  styleUrls: ['./dentbox-work-request-list.component.scss']
})
export class DentboxWorkRequestListComponent implements OnInit {
  @ViewChild('statusInput') statusInputVariable: ElementRef;

  public user: Clinic | Lab;
  public user_type: string;
  public user_id: string;

  /* Pagination */
  currentPage = 1;
  pageSize = 15;
  collectionSize = 15;


  // Status list
  active = 'active';
  pending = 'pending';
  rejected = 'rejected';
  finalized = 'finalized';
  closed = 'closed';

  // Filter options
  dentboxWorkListFilterModel: DentboxWorkListFilterModel;
  filterModel: DentboxWorkListFilterModel;
  filterDataList: any[] = [];

  labDescription = new FormControl();
  clinicDescription = new FormControl();
  collaboratorDescription = new FormControl();
  patientDescription = new FormControl();

  public relatedLabs: any[] = [];
  public relatedClinics: any[] = [];
  public patientList: any[] = [];
  public collaboratorList: any[] = [];
  public profileList: WorkListProfile[] = [];

  public labsDropdownList: { id: number | null, name: string }[] = [];
  public clinicsDropdownList: { id: number | null, name: string }[] = [];
  public collaboratorDropdownList: { id: string | null, name: string }[] = [];
  public patientDropdownList: { id: string | null, name: string }[] = [];

  isAttachmentPopupOpen: boolean = false;
  selectedFile: any;
  selectedWorkIdForFile: any;

  FILE_SERVER_URL = ApiConnection.FILE_SERVER_URL;

  profileForm: FormGroup;
  selectedProfile: any;
  isEditProfile: boolean = false;

  constructor(private _router: Router, private _chatService: ChatService,
    private _expandViewService: ExpandViewService,
    public sanitizer: DomSanitizer,
    private _uploadService: UploadService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _profileService: ProfileService,
    private _toastrService: ToastrService,
    private _languageService: LanguageService,
    private featureFlagService: FeatureFlagService
  ) {
    this.isAttachmentPopupOpen = false;
    this.selectedFile = null;
    this.selectedWorkIdForFile = null;
    this.profileList = [];
    this.initializeForm();
  }

  async ngOnInit(): Promise<void> {
    // Feature flag check
    if (!this.featureFlagService.isFeatureEnabled('workListExpandView')) {
      this._router.navigate(['/']);
      return;
    }

    if (!sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C') || !sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF')
      || !sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9')) {
      this._router.navigate(['/']);
    }

    this.profileList = [];
    this.selectedProfile = null;
    this.isAttachmentPopupOpen = false;
    this.selectedFile = null;
    this.selectedWorkIdForFile = null;

    this.dentboxWorkListFilterModel = new DentboxWorkListFilterModel();
    this.filterModel = new DentboxWorkListFilterModel();
    this.initializeForm();
    this.filterDataList = [];
    this.currentPage = 1;

    this.user_type = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    this.user_id = sessionStorage.getItem('4CDC84B1E0607D91E3C7BBDE07EECC6A');

    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    if (data) {
      this.user = JSON.parse(data);
      await this.loadList();
    }

    this.setInitialFilters();
    this.filterList();

  }

  initializeForm() {
    // Initialize the form group with validation
    this.profileForm = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  async loadList() {
    if (this.user_type && this.user_type === 'lab') {
      await this.getClinicByCenterGuid(this.user.guid);
    }
    else if (this.user_type && this.user_type === 'clinic') {
      await this.getLabsByCenterGuid(this.user.guid);
    }

    await this.loadPatientList();
    await this.loadCollaboratorList();
  }

  async setInitialFilters(isLoadProfiles: boolean = true, isAutoFilter: boolean = false) {
    this.dentboxWorkListFilterModel = new DentboxWorkListFilterModel();
    this.filterModel = new DentboxWorkListFilterModel();
    this.dentboxWorkListFilterModel.dateType = 0;
    this.dentboxWorkListFilterModel.location = 'all';

    this.labDescription.setValue(null);
    this.clinicDescription.setValue(null);
    this.collaboratorDescription.setValue(null);
    this.patientDescription.setValue(null);

    if (this.user_type === 'lab') {
      this.dentboxWorkListFilterModel.labId = this.user._id;
      this.labDescription.setValue(this.user.name);
      this.labDescription.disable();
      this.statusInputVariable.nativeElement.click();
    }
    else if (this.user_type === 'clinic') {
      this.dentboxWorkListFilterModel.clinicId = this.user._id;
      this.clinicDescription.setValue(this.user.name);
      this.clinicDescription.disable();
      this.statusInputVariable.nativeElement.click();
    }

    if (isAutoFilter) {
      this.filterModel = Object.assign(this.filterModel, this.dentboxWorkListFilterModel);
    }

    if (isLoadProfiles) {
      await this.loadProfiles();
    }

  }

  clearFilters() {
    this.setInitialFilters(false, false);
    this.filterList();
  }

  onTodaySelected() {
    if (this.dentboxWorkListFilterModel.isToday) {
      const today = this.getCurrentDate();
      this.dentboxWorkListFilterModel.fromDate = today;
      this.dentboxWorkListFilterModel.toDate = today;
    } else {
      this.dentboxWorkListFilterModel.fromDate = null;
      this.dentboxWorkListFilterModel.toDate = null;
    }
  }

  getCurrentDate(): NgbDateStruct {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
  }

  onDateTypeChange() {
    if (this.dentboxWorkListFilterModel.dateType == 0) {
      this.resetAndDisableFields();
    }
  }

  // Method to disable and clear the fields
  resetAndDisableFields() {
    this.dentboxWorkListFilterModel.isToday = false;
    this.dentboxWorkListFilterModel.fromDate = null;
    this.dentboxWorkListFilterModel.toDate = null;
  }

  // Method to check if the fields should be disabled
  isFieldsDisabled(): boolean {
    return this.dentboxWorkListFilterModel.dateType == 0;
  }

  private async getClinicByCenterGuid(centerGuid: string): Promise<void> {
    this.relatedClinics = [];
    this._expandViewService.getClinicsByCenterGuid(centerGuid).subscribe(
      res => {
        this.relatedClinics = res.data;
        this.initClinicsDropdownList(this.relatedClinics);
      },
      err => console.error(err),
      () => {
      });
  }

  initClinicsDropdownList(list?: any[]) {
    this.clinicsDropdownList = [];
    if (list) {
      list.forEach(clinic => {
        this.clinicsDropdownList.push({ id: clinic.centerId, name: clinic.centerDescription });
      });
    }
  }

  private async getLabsByCenterGuid(centerGuid: string): Promise<void> {
    this.relatedLabs = [];
    this._chatService.getLabsByCenterGuid(centerGuid).subscribe(
      res => {
        this.relatedLabs = res.data;
        this.initLabsDropdownList(this.relatedLabs);
      },
      err => console.error(err),
      () => {
      });
  }

  initLabsDropdownList(list?: any[]) {
    this.labsDropdownList = [];
    if (list) {
      list.forEach(lab => {
        this.labsDropdownList.push({ id: lab.centerId, name: lab.centerDescription });
      });
    }
  }

  private async loadPatientList(): Promise<void> {
    this.patientList = [];
    this._expandViewService.getPatientListByCenterId(this.user._id).subscribe(
      res => {
        this.patientList = res != null && res.length > 0 ? res : [];
        this.initPatientDropdownList(this.patientList);
      },
      err => console.error(err),
      () => {
      });
  }

  initPatientDropdownList(list?: any[]) {
    this.patientDropdownList = [];
    if (list) {
      list.forEach(a => {
        this.patientDropdownList.push({ id: a.patientFullNameAndHistory, name: a.patientFullNameAndHistory });
      });

    }
  }

  private async loadCollaboratorList(): Promise<void> {
    this.collaboratorList = [];
    this._expandViewService.getCollaboratorListByCenterId(this.user._id).subscribe(
      res => {
        this.collaboratorList = res != null && res.length > 0 ? res : [];
        this.initCollaboratorDropdownList(this.collaboratorList);
      },
      err => console.error(err),
      () => {
      });
  }

  initCollaboratorDropdownList(list?: any[]) {
    this.collaboratorDropdownList = [];
    if (list) {
      list.forEach(a => {
        this.collaboratorDropdownList.push({ id: a.collaboratorDescription, name: a.collaboratorDescription });
      });

    }
  }

  async onSelectLab(lab?: any) {
    try {
      if (lab) {
        this.dentboxWorkListFilterModel.labId = lab.id;
      } else {
        this.dentboxWorkListFilterModel.labId = null;
        this.labDescription.setValue(null);
      }
    } catch (error) { console.log(error); } finally {
    }
  }

  async onSelectClinic(item?: any) {
    try {
      if (item) {
        this.dentboxWorkListFilterModel.clinicId = item.id;
      } else {
        this.dentboxWorkListFilterModel.clinicId = null;
        this.clinicDescription.setValue(null);
      }
    } catch (error) { console.log(error); } finally {
    }
  }

  async onSelectCollaborator(item?: any) {
    try {
      if (item) {
        this.dentboxWorkListFilterModel.collaborator = item.id;
      } else {
        this.dentboxWorkListFilterModel.collaborator = null;
        this.collaboratorDescription.setValue(null);
      }
    } catch (error) { console.log(error); } finally {
    }
  }

  async onSelectPatient(item?: any) {
    try {
      if (item) {
        this.dentboxWorkListFilterModel.patient = item.id;
      } else {
        this.dentboxWorkListFilterModel.patient = null;
        this.patientDescription.setValue(null);
      }
    } catch (error) { console.log(error); } finally {
    }
  }

  getPaginatedList() {
    this.isAttachmentPopupOpen = false;
    this.selectedFile = null;
    this.selectedWorkIdForFile = null;
    this.filterDataList = [];

    const params = new URLSearchParams();

    // Pagination parameters
    params.append('page', this.currentPage.toString());
    params.append('pageSize', this.pageSize.toString());

    //Filter parameters from DentboxWorkListFilterModel
    if (this.filterModel.status && this.filterModel.status.length > 0) {
      params.append('status', this.filterModel.status.join(','));
    }
    if (this.filterModel.dateType) {
      params.append('dateType', this.filterModel.dateType);
    }
    if (this.filterModel.isToday != undefined) {
      params.append('isToday', this.filterModel.isToday.toString());
    }
    if (this.filterModel.fromDate) {
      const fromDate: NgbDateStruct = this.filterModel.fromDate;
      const formattedDate = `${fromDate.year}-${fromDate.month.toString().padStart(2, '0')}-${fromDate.day.toString().padStart(2, '0')}`;
      params.append('fromDate', formattedDate);
    }
    if (this.filterModel.toDate) {
      const toDate: NgbDateStruct = this.filterModel.toDate;
      const formattedToDate = `${toDate.year}-${toDate.month.toString().padStart(2, '0')}-${toDate.day.toString().padStart(2, '0')}`;
      params.append('toDate', formattedToDate);
    }
    if (this.filterModel.clinicId) {
      params.append('clinicId', this.filterModel.clinicId);
    }
    if (this.filterModel.collaborator) {
      params.append('collaborator', this.filterModel.collaborator);
    }
    if (this.filterModel.labId) {
      params.append('labId', this.filterModel.labId);
    }
    if (this.filterModel.patient) {
      params.append('patient', this.filterModel.patient);
    }
    if (this.filterModel.location && this.filterModel.location != 'all') {
      params.append('location', this.filterModel.location);
    }

    // Make the request
    this._expandViewService.getPaginatedWorkList(params)
      .subscribe((response: PaginationResponse) => {
        this.collectionSize = response.collectionSize;
        this.filterDataList = response.list;
      },
        err => { },
        () => {
        });

  }

  filterList() {
    this.filterModel = new DentboxWorkListFilterModel();
    this.filterModel = Object.assign(this.filterModel, this.dentboxWorkListFilterModel);
    this.filterDataList = [];
    this.collectionSize = 0;
    this.getPaginatedList();
  }

  onViewAttachment(data: any) {
    this.selectedFile = null;
    this.selectedWorkIdForFile = null;

    if (data.selectedFile && data.selectedFile !== 'null') {
      this.selectedFile = data.selectedFile;
      this.selectedWorkIdForFile = data._id;

      const officeUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';
      if (this.selectedFile.extension === '.docx' || this.selectedFile.extension === '.xlsx' || this.selectedFile.extension === '.xls' || this.selectedFile.extension === '.doc') {
        let url = officeUrl + this.FILE_SERVER_URL + this.selectedWorkIdForFile + '/' + this.selectedFile.url;
        this.selectedFile.safeUrl = this.getTrustedUrl(url);
      }
      else {
        this.selectedFile.safeUrl = this.getTrustedUrl(this.FILE_SERVER_URL + this.selectedWorkIdForFile + '/' + this.selectedFile.url);
      }

      this.isAttachmentPopupOpen = true;
    }
  }

  private getTrustedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onDownloadAttachment(data: any) {
    this.selectedFile = null;
    this.selectedWorkIdForFile = null;

    if (data.selectedFile && data.selectedFile !== 'null') {
      this.selectedFile = data.selectedFile;
      this.selectedWorkIdForFile = data._id;

      this._uploadService.downloadFile(this.selectedFile.newName, this.selectedWorkIdForFile)
        .subscribe(
          data => saveAs(data, this.selectedFile.originalName),
          error => console.error(error)
        );
    }
  }

  public download(url: string, originalName: string) {
    if (url) {
      const filename = url.split('/')[url.split('/').length - 1];
      if (filename) {
        this._uploadService.downloadFile(filename, this.selectedWorkIdForFile)
          .subscribe(
            data => saveAs(data, originalName),
            error => console.error(error)
          );
      } else {
        console.log('Invalid file name!');
      }
    }
  }

  onCloseModal() {
    this.isAttachmentPopupOpen = false;
  }

  onViewWorkChat(data: any) {
    if (data && data.guid) {
      // Store workGuid in sessionStorage
      sessionStorage.setItem('5EC358E33FC855F0268D055B457B1D68', data.guid);

      const isOpenFromDentbox = true;
      window.open(`/work-chat?isOpenFromDentbox=${isOpenFromDentbox}`, '_blank');
    }
  }

  openAddProfileModal(content: any): void {
    this.isEditProfile = false;
    this.profileForm.reset();
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  openEditProfileModal(content: any): void {
    if (this.selectedProfile && this.selectedProfile !== "null") {
      this.isEditProfile = true;
      this.profileForm.reset();
      this.profileForm.controls.name.setValue(this.selectedProfile.profileName);
      this.modalService.open(content, { centered: true, size: 'lg' });
    }
  }

  deleteProfile(): void {
    if (this.selectedProfile && this.selectedProfile !== "null") {
      this._profileService.deleteProfile(this.selectedProfile).subscribe(
        async (res) => {
          if (res) {
            const successMsge = await this._languageService.getTranslation('common.delete_success');
            this._toastrService.success(successMsge);

            this.selectedProfile = null;
            this.loadProfiles();
            await this.setInitialFilters(false, true);
            this.getPaginatedList();
          }
          else {
            const successMsge = await this._languageService.getTranslation('common.delete_error');
            this._toastrService.success(successMsge);
          }
        },
        async (err) => {
          console.error(err);
          this.selectedProfile = null;
          const successMsge = await this._languageService.getTranslation('common.delete_error');
          this._toastrService.success(successMsge);
          await this.loadProfiles();
        }
      );
    }
  }

  closeModal(modal: any): void {
    modal.dismiss();
  }

  async onSaveProfile(): Promise<void> {
    if (this.profileForm.valid) {
      try {
        let profileData = new WorkListProfile();
        profileData.profileName = this.profileForm.get('name')?.value;
        profileData.clinicId = this.user_type === 'clinic' ? this.user._id : null;
        profileData.labId = this.user_type === 'lab' ? this.user._id : null;
        profileData.filters = "";

        const filters = this.filterModel
          ? {
            clinicId: this.filterModel.clinicId || null,
            labId: this.filterModel.labId || null,
            status: this.filterModel.status?.length > 0
              ? this.filterModel.status.join(',')
              : null,
            dateType: this.filterModel.dateType || 0,
            isToday: this.filterModel.isToday || false,
            fromDate: this.filterModel.fromDate
              ? `${this.filterModel.fromDate.year}-${this.filterModel.fromDate.month
                .toString()
                .padStart(2, '0')}-${this.filterModel.fromDate.day
                  .toString()
                  .padStart(2, '0')}`
              : null,
            toDate: this.filterModel.toDate
              ? `${this.filterModel.toDate.year}-${this.filterModel.toDate.month
                .toString()
                .padStart(2, '0')}-${this.filterModel.toDate.day
                  .toString()
                  .padStart(2, '0')}`
              : null,
            collaborator: this.filterModel.collaborator || null,
            location: this.filterModel.location || null,
            patient: this.filterModel.patient || null,
          }
          : {};

        profileData.filters = JSON.stringify(filters);

        this._profileService.saveProfile(profileData).subscribe(
          async (res) => {
            if (res && res.profile) {
              const successMsge = await this._languageService.getTranslation('common.save_success');
              this._toastrService.success(successMsge);
              this.modalService.dismissAll();
              this.profileList.push(res.profile);
              this.selectedProfile = this.profileList.find((profile) => profile._id === res.profile._id) || null;
              this.profileList.sort((a, b) => a.profileName.localeCompare(b.profileName));

            }
            else {
              this.selectedProfile = null;
              const successMsge = await this._languageService.getTranslation('common.save_error');
              this._toastrService.success(successMsge);

              await this.loadProfiles();
              this.modalService.dismissAll();
            }
          },
          async (err) => {
            console.error(err);
            this.selectedProfile = null;
            const successMsge = await this._languageService.getTranslation('common.save_error');
            this._toastrService.success(successMsge);

            await this.loadProfiles();
            this.modalService.dismissAll();
          }
        );

      } catch (error) {
        const successMsge = await this._languageService.getTranslation('common.save_error');
        this._toastrService.success(successMsge);
        console.error('Error while saving profile:', error);
      }
    } else {
      this.profileForm.markAllAsTouched();
    }
  }

  async loadProfiles(): Promise<void> {
    try {
      this.profileList = [];
      if (this.user_type === 'lab') {
        await this.getProfilesByLabId();
      } else if (this.user_type === 'clinic') {
        await this.getProfilesByClinicId();
      }
    } catch (error) {
      console.error('Error loading profiles:', error.message);
      this.profileList = [];
    }
  }

  private async getProfilesByClinicId(): Promise<void> {
    this.profileList = [];
    this._profileService.getProfilesByClinicId(this.user._id).subscribe(
      res => {
        this.profileList = res != null && res.length > 0 ? res : [];
        this.profileList.sort((a, b) => a.profileName.localeCompare(b.profileName));

      },
      err => console.error(err),
      () => {
      });
  }

  private async getProfilesByLabId(): Promise<void> {
    this.profileList = [];
    this._profileService.getProfilesByLabId(this.user._id).subscribe(
      res => {
        this.profileList = res != null && res.length > 0 ? res : [];
        this.profileList.sort((a, b) => a.profileName.localeCompare(b.profileName));
      },
      err => console.error(err),
      () => {
      });
  }

  onProfileChange() {
    if (this.selectedProfile && this.selectedProfile !== "null") {
      const filters = JSON.parse(this.selectedProfile.filters);

      // Assign values from the selected profile to the filterModel
      this.filterModel = {
        clinicId: filters.clinicId || null,
        labId: filters.labId || null,
        status: filters.status ? filters.status.split(',') : [],
        dateType: filters.dateType || 0,
        isToday: filters.isToday || false,
        fromDate: filters.fromDate ? this.convertToNgbDate(filters.fromDate) : null,
        toDate: filters.toDate ? this.convertToNgbDate(filters.toDate) : null,
        collaborator: filters.collaborator || null,
        location: filters.location || null,
        patient: filters.patient || null,
      };

      this.filterModel.isToday = this.filterModel.isToday != true ? false : true;

      this.dentboxWorkListFilterModel = Object.assign(this.dentboxWorkListFilterModel, this.filterModel);
      this.patientDescription.setValue(this.filterModel.patient);
      this.collaboratorDescription.setValue(this.filterModel.collaborator);

      let lab = this.labsDropdownList.find(a => a.id == this.filterModel.labId);
      if (lab) {
        this.labDescription.setValue(lab.name);
      }

      let clinic = this.labsDropdownList.find(a => a.id == this.filterModel.clinicId);
      if (clinic) {
        this.clinicDescription.setValue(clinic.name);
      }

      this.onTodaySelected();
      this.getPaginatedList();

    }
    else {
      this.isEditProfile = false;
      this.selectedProfile = null;
      this.setInitialFilters(false, true);
      this.getPaginatedList();
    }
  }

  // Helper method to convert a date string to NgbDateStruct
  convertToNgbDate(dateString: string): NgbDateStruct {
    const [year, month, day] = dateString.split('-').map(Number);
    return { year, month, day };
  }

  async onEditProfile(): Promise<void> {
    if (this.profileForm.valid) {
      try {
        let profileData = new WorkListProfile();
        profileData.profileName = this.profileForm.get('name')?.value;
        profileData.clinicId = this.user_type === 'clinic' ? this.user._id : null;
        profileData.labId = this.user_type === 'lab' ? this.user._id : null;
        profileData.filters = "";

        const filters = this.filterModel
          ? {
            clinicId: this.filterModel.clinicId || null,
            labId: this.filterModel.labId || null,
            status: this.filterModel.status?.length > 0
              ? this.filterModel.status.join(',')
              : null,
            dateType: this.filterModel.dateType || 0,
            isToday: this.filterModel.isToday || false,
            fromDate: this.filterModel.fromDate
              ? `${this.filterModel.fromDate.year}-${this.filterModel.fromDate.month
                .toString()
                .padStart(2, '0')}-${this.filterModel.fromDate.day
                  .toString()
                  .padStart(2, '0')}`
              : null,
            toDate: this.filterModel.toDate
              ? `${this.filterModel.toDate.year}-${this.filterModel.toDate.month
                .toString()
                .padStart(2, '0')}-${this.filterModel.toDate.day
                  .toString()
                  .padStart(2, '0')}`
              : null,
            collaborator: this.filterModel.collaborator || null,
            location: this.filterModel.location || null,
            patient: this.filterModel.patient || null,
          }
          : {};

        profileData.filters = JSON.stringify(filters);
        profileData._id = this.selectedProfile._id;

        this._profileService.updateProfile(profileData).subscribe(
          async (res) => {
            if (res && res.profile) {
              const successMsge = await this._languageService.getTranslation('common.update_success');
              this._toastrService.success(successMsge);
              this.modalService.dismissAll();
              this.profileList = this.profileList.filter((profile) => profile._id != res.profile._id) || [];
              this.profileList.push(res.profile);
              this.profileList.sort((a, b) => a.profileName.localeCompare(b.profileName));

              this.selectedProfile = this.profileList.find((profile) => profile._id === res.profile._id) || [];
            }
            else {
              this.selectedProfile = null;
              const successMsge = await this._languageService.getTranslation('common.update_error');
              this._toastrService.success(successMsge);

              await this.loadProfiles();
              this.modalService.dismissAll();
            }
          },
          async (err) => {
            console.error(err);
            this.selectedProfile = null;
            const successMsge = await this._languageService.getTranslation('common.update_error');
            this._toastrService.success(successMsge);

            await this.loadProfiles();
            this.modalService.dismissAll();
          }
        );

      } catch (error) {
        const successMsge = await this._languageService.getTranslation('common.save_error');
        this._toastrService.success(successMsge);
        console.error('Error while saving profile:', error);
      }
    } else {
      this.profileForm.markAllAsTouched();
    }
  }


}

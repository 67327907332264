import { UntypedFormGroup } from "@angular/forms";
import { PiezasLower, PiezasTop } from "./global";
import { DatePipe } from "@angular/common";

export class CommonFunctions {

  static showInvalidFormControls(formGroup: UntypedFormGroup): void {
    if (formGroup !== undefined) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  static showError(error: string): void {
    console.log('Error:: dentbox: ' + error);
  }

  static checkPasswords(group: UntypedFormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true };
  }

  static GetPatientAutoCompleteList(list: any[], name: string, dob: string): any[] {
    if (list) {
      const autoCompleteList = [];
      const datePipe = new DatePipe('en-US');
      list.forEach(item => autoCompleteList.push({
        display: item[name] + ' ' + (item['surnames'] ? item['surnames'] : '') +
          (item[dob] ? ' - ' + datePipe.transform(item[dob], 'dd/MM/yyyy') : '') +
          (item['historyNumber'] ? (' - ' + ' ' + item['historyNumber']) : ''),
        value:
          item[name] + ' ' + (item['surnames'] ? item['surnames'] : '') +
          (item[dob] ? ' - ' + datePipe.transform(item[dob], 'dd/MM/yyyy') : '') +
          (item['historyNumber'] ? (' - ' + ' ' + item['historyNumber']) : ''),
        id: item.id
      }));
      return autoCompleteList;
    }
  }

  public static ValidatePiezasNumber(input: string) {
    try {
      if (input.slice(input.length - 1) == ",")
        return input;

      const range = ""
      const singlePieces: string[] = [];
      const multipleValues = input.split(',')

      if (multipleValues.length >= 1 && input != "") {
        let result = []
        let output: number[] = []

        //get pieces ranges and values
        for (let value of multipleValues) {
          result = this.getPiecesList(value, range, singlePieces, PiezasTop, PiezasLower)

          if (result[0] == "invalidValue" || result[0] == "invalidRange") {
            return result[0];
          }

          if (result != undefined && result.length != 0)
            output = output.concat(result)
        }

        //get last range or value
        let lastArr: any = output[output.length - 1]

        //convert single value to array
        if (!Array.isArray(lastArr)) lastArr = [lastArr]

        //remove last value or range from the output
        output.pop()

        //remove duplicates from the last array or value
        const removeDuplicationsFromLastValue = this.removeDuplications(output, lastArr);

        //if the result is still a range then show as a range(i.e with "-")
        const valueOrRange = removeDuplicationsFromLastValue.length >= 2 ? removeDuplicationsFromLastValue.join("-") : removeDuplicationsFromLastValue

        const outputArray: any = []

        //create final output
        output.forEach(item => {
          if (!Array.isArray(item)) {
            outputArray.push(item)
          } else {
            outputArray.push(item.join("-"))
          }
        })
        return outputArray.concat(valueOrRange)
      }
    } catch (error) {
    }
  }

  private static getPiecesList(input: string, range: string, singlePieces: string[], pTop: any[], pLower: any[]): any {
    if (input.length > 5) {
      return [...singlePieces.map(Number), input.split('-')];
    }

    const result = input.includes(",");
    if (result) {
      const values = input.split(',');
      values.forEach(value => {
        const isRange = value.includes("-");

        if (isRange) {
          range = value;
          const index = values.findIndex(x => x === value)
          values.splice(index, 1)
          singlePieces = values
        }
      })
    } else {
      range = input;
    }

    if (range != "" || !result) {
      const rangeValues = range.split('-');

      if (input.length >= 1) {
        let searchInUpper: boolean = false;
        let invalidInput = false;

        //for mutiple values
        if (this.multipleExist(pTop, rangeValues)) {
          searchInUpper = true
        } else if (this.multipleExist(pLower, rangeValues)) {
          searchInUpper = false
        } else {
          if (!searchInUpper && range.includes('-')) {
            return ["invalidRange"];
          }
          invalidInput = true;
          return ["invalidValue"];
        }

        //remove single vals
        if (rangeValues.length == 1)
          return input

        if (rangeValues.length == 2 && !invalidInput) {
          const rangeList = searchInUpper ? this.getRangeValuesFromList(pTop, rangeValues) : this.getRangeValuesFromList(pLower, rangeValues)
          return [...singlePieces.map(Number), rangeList];
        }
      }
    }
  }

  private static getRangeValuesFromList(pieces: number[], inputVal: string[]): number[] {
    const rangeValues: number[] = []
    const keys: number[] = [];
    //sonarqube fix - changed filter to foreach
    pieces.forEach((e, i) => {
      if (e === Number(inputVal[0])) {
        keys.push(i);
      }
      if (e === Number(inputVal[1])) {
        keys.push(i);
      }
    });

    for (let i = keys[0]; pieces.length; i++) {
      rangeValues.push(pieces[i])
      if (i == keys[1]) break;
    }
    return rangeValues
  }

  private static multipleExist(numberArray: number[], checkValues: string[]) {
    return checkValues.every(value => {
      return numberArray.includes(Number(value));
    });
  }

  private static removeDuplications(arr: any[], keys: any) {
    keys.forEach(key => {
      arr.forEach(e => {
        if (!Array.isArray(e)) {
          if (Number(key) === Number(e)) keys.splice(keys.findIndex(k => k == e), 1)
        } else if (Array.isArray(e)) {
          this.removeDuplications(e, keys)
        }
      })
    });
    return keys;
  }


}

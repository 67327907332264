import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'linear-gradient(120deg, #00e4d0, #5983e8)',
    bgsOpacity: 0.5,
    bgsPosition: POSITION.centerCenter,
    bgsSize: 60,
    bgsType: SPINNER.squareLoader,
    fgsColor: '#ffff',
    fgsPosition: POSITION.centerCenter,
    fgsSize: 35,
    fgsType: SPINNER.squareLoader,
    pbColor: 'linear-gradient(120deg, #00e4d0, #5983e8)',
    pbDirection: PB_DIRECTION.leftToRight,
    pbThickness: 5,
    textColor: '#FFFFFF',
    textPosition: POSITION.centerCenter
};

export const PiezasTop = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28	];

export const PiezasLower = [38, 37, 36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48	];

<div id="no-padding" class="container-fluid">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3  col-lg-3 side">
      <div class="side-one">
        <chat-side-bar></chat-side-bar>
      </div>
      <!-- Sidebar End -->
    </div>
    <!-- New Message Sidebar End -->
    <!-- Conversation Start -->
    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9 conversation">
      <chat-heading></chat-heading>
      <message-box></message-box>
      <chat-rightbar></chat-rightbar>
      <div class="overlay-right"></div>
    </div>
    <!-- Conversation End -->
    <div class="overlay-left">
      <chat-leftbar></chat-leftbar>
    </div>
  </div>
  <!-- App One End -->
</div>
<!-- App End -->

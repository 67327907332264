<div id="no-padding" class="app work-chat-div">

    <div class="row app-one">

        <!-- Conversation Start -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 conversation">

            <chat-heading [hasProvidedWork]="true" [isOpenFromDentbox]="isOpenFromDentbox"></chat-heading>

            <message-box [hasProvidedWork]="true" [isOpenFromDentbox]="isOpenFromDentbox"></message-box>

            <chat-rightbar></chat-rightbar>

        </div>
        <!-- Conversation End -->

    </div>
    <!-- App One End -->
</div>

<!-- App End -->

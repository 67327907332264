<app-header-nav-bar></app-header-nav-bar>

<div class="row work-request-list p-3">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="row">
          <!-- detail section -->
          <div class="col-xl-10 col-lg-9 col-md-9 col-sm-9">
            <div class="row border-card">
              <div class="col-md-5">
                <div class="form-group multiselect-options">
                  <label #statusInput>{{ 'expand_view.status' | translate }}</label>
                  <ng-select [(ngModel)]="dentboxWorkListFilterModel.status" class="form-control" [multiple]="true">
                    <ng-option [value]="active">{{ 'resource.active' | translate }}</ng-option>
                    <ng-option [value]="pending">{{ 'resource.pending' | translate }}</ng-option>
                    <ng-option [value]="rejected">{{ 'resource.rejected' | translate }}</ng-option>
                    <ng-option [value]="finalized">{{ 'resource.finalized' | translate }}</ng-option>
                    <ng-option [value]="closed">{{ 'resource.closed' | translate }}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>{{ 'expand_view.date_type' | translate }}</label>
                  <select class="form-control" [(ngModel)]="dentboxWorkListFilterModel.dateType" (change)="onDateTypeChange()">
                    <option value="0">{{ 'resource.select' | translate }}</option>
                    <option value="1">{{ 'resource.order_date' | translate }}</option>
                    <option value="2">{{ 'resource.delivery_date' | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label class="visibility-hidden"></label>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" [(ngModel)]="dentboxWorkListFilterModel.isToday"
                        (change)="onTodaySelected()" [disabled]="isFieldsDisabled()">{{ 'expand_view.today' | translate }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>{{ 'expand_view.from_date' | translate }}</label>
                  <input class="form-control" type="text" [(ngModel)]="dentboxWorkListFilterModel.fromDate"
                    name="dpfrom" ngbDatepicker #df="ngbDatepicker" (click)="df.toggle()" autocomplete="off"
                    [disabled]="isFieldsDisabled() || dentboxWorkListFilterModel.isToday">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>{{ 'expand_view.to_date' | translate }}</label>
                  <input class="form-control" type="text" [(ngModel)]="dentboxWorkListFilterModel.toDate" name="dpto"
                    ngbDatepicker #dt="ngbDatepicker" (click)="dt.toggle()" autocomplete="off"
                    [disabled]="isFieldsDisabled() || dentboxWorkListFilterModel.isToday">
                </div>
              </div>
              <div class="col-md-2 nova-search-div">
                <div class="form-group">
                  <label>{{ 'expand_view.clinic' | translate }}</label>
                  <nova-search class="clinic-search common-nova-search" [items]="clinicsDropdownList" autocomplete="off"
                    [options]="{ key:'name', display:'name' }" [skipAccents]="true"
                    [inputCss]="'form-control form-control-sm'" [optionCss]="'clinic-css modreservation-client-css'"
                    [control]="clinicDescription" [elementId]="'clinic-name'"
                    [placeholder]="'common.type_here' | translate" (onItemSelected)="onSelectClinic($event)"
                    (onClearText)="onSelectClinic()">
                  </nova-search>
                </div>
              </div>
              <div class="col-md-2 nova-search-div">
                <div class="form-group">
                  <label>{{ 'expand_view.collaborator' | translate }}</label>
                  <nova-search class="collaborator-search common-nova-search" [items]="collaboratorDropdownList" autocomplete="off"
                    [options]="{ key:'name', display:'name' }" [skipAccents]="true"
                    [inputCss]="'form-control form-control-sm'"
                    [optionCss]="'collaborator-css modreservation-client-css'" [control]="collaboratorDescription"
                    [elementId]="'collaborator-name'" [placeholder]="'common.type_here' | translate"
                    (onItemSelected)="onSelectCollaborator($event)" (onClearText)="onSelectCollaborator()">
                  </nova-search>
                </div>
              </div>
              <div class="col-md-2 nova-search-div">
                <div class="form-group">
                  <label>{{ 'expand_view.lab' | translate }}</label>
                  <nova-search class="lab-search common-nova-search" [items]="labsDropdownList" autocomplete="off"
                    [options]="{ key:'name', display:'name' }" [skipAccents]="true"
                    [inputCss]="'form-control form-control-sm'" [optionCss]="'lab-css modreservation-client-css'"
                    [control]="labDescription" [elementId]="'lab-name'" [placeholder]="'common.type_here' | translate"
                    (onItemSelected)="onSelectLab($event)" (onClearText)="onSelectLab()">
                  </nova-search>
                </div>
              </div>
              <div class="col-md-2 nova-search-div">
                <div class="form-group">
                  <label>{{ 'expand_view.patient' | translate }}</label>
                  <nova-search class="patient-search common-nova-search" [items]="patientDropdownList" autocomplete="off"
                    [options]="{ key:'name', display:'name' }" [skipAccents]="true"
                    [inputCss]="'form-control form-control-sm'" [optionCss]="'patient-css modreservation-client-css'"
                    [control]="patientDescription" [elementId]="'patient-name'"
                    [placeholder]="'common.type_here' | translate" (onItemSelected)="onSelectPatient($event)"
                    (onClearText)="onSelectPatient()">
                  </nova-search>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>{{ 'expand_view.location' | translate }}</label>
                  <select class="form-control" [(ngModel)]="dentboxWorkListFilterModel.location">
                    <option value="all">{{ 'resource.select' | translate }}</option>
                    <option value="c">{{ 'expand_view.clinic' | translate }}</option>
                    <option value="l">{{ 'expand_view.lab' | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 pt-4 mt-3">
                <div class="form-group d-inline-block">
                  <button class="btn btn-primary search-button" (click)="filterList()">{{ 'expand_view.search' | translate }}</button>
                  <button class="btn btn-secondary ml-2" (click)="clearFilters()">{{ 'expand_view.clear' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <!-- profile section -->
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3">
            <div class="border-card">
              <div class="row profile-card">
                <div class="col-md-8">
                  <h5>{{ 'filterProfile.profile' | translate }}</h5>
                </div>
                <div class="col-md-4 text-right">
                  <a class="float-right add-link">
                    <span class="fa fa-trash" (click)="deleteProfile()"></span>
                  </a>
                  <a class="float-right add-link m-r-5">
                    <span class="fa fa-pencil" (click)="openEditProfileModal(addProfileModal)"></span>
                  </a>
                  <a class="float-right add-link m-r-5" (click)="openAddProfileModal(addProfileModal)">
                    <span class="fa fa-plus"></span>
                  </a>
                </div>
                <div class="col-md-12 pt-3">
                  <div class="form-group">
                    <p>{{ 'filterProfile.searchProfile' | translate }}</p>
                    <select class="form-control" [(ngModel)]="selectedProfile" (change)="onProfileChange()">
                      <option value="null">{{ 'resource.select' | translate }}</option>
                      <option *ngFor="let profile of profileList" [ngValue]="profile">
                        {{ profile.profileName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- list table section -->
        <div class="row mt-3 border-card">
          <div class="col-md-12">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ 'expand_view.status' | translate }}</th>
                  <th>{{ 'expand_view.date_of_entry' | translate }}</th>
                  <th>{{ 'expand_view.delivery_date' | translate }}</th>
                  <th>{{ 'expand_view.clinic_description' | translate }}</th>
                  <th>{{ 'expand_view.collaborator_description' | translate }}</th>
                  <th>{{ 'expand_view.lab_description' | translate }}</th>
                  <th>{{ 'expand_view.patient_history_no' | translate }}</th>
                  <th>{{ 'expand_view.location' | translate }}</th>
                  <th>{{ 'expand_view.attachments' | translate }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of filterDataList;let i = index">
                  <td> {{ (data.state == 'active' ? 'resource.active' :
                    data.state == 'pending' ? 'resource.pending' :
                    data.state == 'rejected' ? 'resource.rejected' :
                    data.state == 'finalized' ? 'resource.finalized' :
                    data.state == 'closed' ? 'resource.closed' : ''
                    ) | translate }}</td>
                  <td> {{ data.entryDate }}</td>
                  <td> {{ data.deliveryDate }}</td>
                  <td> {{ data.clinicDescription }}</td>
                  <td> {{ data.collaboratorDescription }}</td>
                  <td> {{ data.labDescription }}</td>
                  <td> {{ data.patientDetails }}</td>
                  <td> {{ (data.workItemLocation == 'c'? 'expand_view.clinic' : 'expand_view.lab') | translate }}</td>
                  <td>
                    <select *ngIf="data.attachments && data.attachments.length > 0" [(ngModel)]="data.selectedFile" class="w-100 file-dropdown">
                      <option class="selected" value="null">{{ 'resource.select' | translate }}</option>
                      <option *ngFor="let file of data.attachments; let i = index" [ngValue]="file" class="option">
                        {{file.originalName}}</option>
                    </select>
                  </td>
                  <td>
                    <em class="fa fa-eye m-r-10" aria-hidden="true"
                      ngbTooltip="{{ 'expand_view.view_attachment' | translate }}"
                      (click)="onViewAttachment(data)"></em>
                    <em class="fa fa-download m-r-10" aria-hidden="true"
                      ngbTooltip="{{ 'expand_view.download' | translate }}" (click)="onDownloadAttachment(data)"></em>
                    <em class="fa fa-comment-o" aria-hidden="true"
                      ngbTooltip="{{ 'expand_view.view_chat' | translate }}" (click)="onViewWorkChat(data)"></em>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- pagination section -->
          <div class="row pagination-row p-l-10">
            <div class="col-md-12">
              <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="currentPage"
                [maxSize]="5" [boundaryLinks]="true" (pageChange)="getPaginatedList()">
                <ng-template ngbPaginationNumber let-page let-current="current">
                  <span *ngIf="!current">{{ page }}</span>
                  <span *ngIf="current" class="btn btn-primary active">{{ page }}</span>
                </ng-template>
              </ngb-pagination>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- View attachments -->
  <div id="theModal-attachments" class="modal fade text-center attachment-popup d-block"
    *ngIf="isAttachmentPopupOpen && selectedFile">
    <div class="modal-dialog modal-lg" style="top: 25% !important;">
      <div class="modal-content attachment-popup">
        <div class="modal-header attachment-popup-header">
          <span class="text-center">{{ selectedFile.originalName }}
            <button class=" pull-left btn btn-xs btn-default"
              (click)="download(FILE_SERVER_URL + selectedWorkIdForFile + '/' + selectedFile.url, selectedFile.originalName)">
              {{ 'resource.download' | translate }}
              <em class="fa fa-angle-double-down" aria-hidden="true"></em>
            </button>
            <button type="button pull-right" class="close" data-dismiss="modal"
              (click)="onCloseModal()">&times;</button>
          </span>
        </div>
        <div class="modal-body attachment-popup-body">
          <div class="text-center">
            <img
              *ngIf="selectedFile.extension !== '.txt' && selectedFile.extension !== '.docx' && selectedFile.extension !== '.pdf' && selectedFile.extension !== '.xlsx' && selectedFile.extension !== '.xls' && selectedFile.extension !== '.doc'"
              src="{{ FILE_SERVER_URL + selectedWorkIdForFile + '/' + selectedFile.url }}"
              alt="Attachment {{ selectedFile.newName }}" class="img-style">
            <iframe title="Iframe" [src]="selectedFile.safeUrl" class="iframe-style iframe-doc-view" frameborder="0" width="660"
              webkitallowfullscreen mozallowfullscreen allowfullscreen
              *ngIf="selectedFile.extension === '.docx' || selectedFile.extension === '.pdf' || selectedFile.extension === '.xlsx' || selectedFile.extension === '.xls' || selectedFile.extension === '.doc'">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #addProfileModal let-modal>
    <form [formGroup]="profileForm">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"> {{ (isEditProfile ? 'filterProfile.editProfile' :
          'filterProfile.addProfile') | translate }}
        </h4>
      </div>
      <div class="modal-body add-work-popup">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>{{ 'filterProfile.profileName' | translate }}</label>
              <input type="text" class="form-control" aria-label="Patient Data" formControlName="name" />
              <div *ngIf="profileForm.get('name')?.touched && profileForm.get('name')?.invalid" class="text-danger">
                {{ 'common.required' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onSaveProfile()" *ngIf="isEditProfile != true">{{
          'common.save' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="onEditProfile()" *ngIf="isEditProfile">{{ 'common.update'
          | translate }}</button>
        <button type="button" class="btn btn-secondary" (click)="closeModal(modal)">{{ 'common.cancel' | translate
          }}</button>
      </div>
    </form>
  </ng-template>

  <div class="overlay-left">
    <chat-leftbar></chat-leftbar>
  </div>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{(isEdit ? 'work.editWork' : 'work.addWork') | translate}}</h4>
</div>
<div class="modal-body add-work-popup">
  <form [formGroup]="workFormGroup">
    <div class="row">
      <div class="col-md-3 nova-search-div">
        <div class="form-group">
          <label>{{ 'work.search_lab' | translate }}</label>
          <nova-search class="modreservation-client-search common-nova-search" [items]="labList" autocomplete="off"
            [options]="{ key:'centerDescription', display:'centerDescription' }" [skipAccents]="true"
            [inputCss]="'form-control form-control-sm'" [optionCss]="'article-css modreservation-client-css'"
            [control]="workFormGroup.controls.labName" [elementId]="'client-name'"
            [placeholder]="'common.type_here' | translate" (onItemSelected)="onSelectLab($event)"
            (onClearText)="onSelectLab()">
          </nova-search>
        </div>
        <div
          *ngIf="workFormGroup.get('labName').touched && !workFormGroup.get('labName').errors?.isValid && workFormGroup.get('labName').errors != null">
          <span class="error-msg">{{ 'common.required' | translate }}</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'work.doctor' | translate }}</label>
          <input type="text" class="form-control" aria-label="doctor" aria-describedby="basic-addon1"
            formControlName="collaborator" (focus)="onFocus(true)" (focusout)="onFocus(false)">
          <div *ngIf=previousDoctorName>
            <ul class="prev-selected-doc">
              <li><a (mousedown)="onSelectDoctor()">{{previousDoctorName}}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'work.patient_name' | translate }}</label>
          <input type="text" class="form-control" aria-label="Patient Data" formControlName="patientName" maxlength="50" autocomplete="off">
        </div>
        <div
          *ngIf="workFormGroup.get('patientName').touched && !workFormGroup.get('patientName').errors?.isValid && workFormGroup.get('patientName').errors != null">
          <span class="error-msg">{{ 'common.required' | translate }}</span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'work.patient_surname' | translate }}</label>
          <input type="text" class="form-control" aria-label="Patient surname" formControlName="patientSurname" maxlength="50" autocomplete="off">
        </div>
      </div>
      <div class="col-md-3">
        <label class="col-form-label">{{ 'resource.patientHistory' | translate }}</label>
        <div class="form-group">
          <input type="text" class="form-control" formControlName="patientHistoryNumber" maxlength="15" autocomplete="off">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="col-form-label">{{ 'resource.orderDate' | translate }}</label>
          <div class="input-group calendar-wrap">
            <input id="entry-date" placeholder="{{ commonUiService.GetPlaceHolderValue() }}" ngbDatepicker
              autocomplete="off" formControlName="entryDate" #entryDate="ngbDatepicker" (click)="entryDate.toggle();"
              class="form-control calendar-input">
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="col-form-label">{{ 'resource.deliverDate' | translate }}</label>
          <div class="input-group calendar-wrap">
            <input id="delivery-date" type="text" placeholder="{{ commonUiService.GetPlaceHolderValue() }}"
              ngbDatepicker autocomplete="off" formControlName="deliveryDate" #deliveryDate="ngbDatepicker"
              (click)="deliveryDate.toggle();" class="form-control calendar-input">
          </div>
        </div>
      </div>
      <div class="col-md-2 pr-0">
        <label class="col-form-label">{{ 'resource.deliverTime' | translate }}</label>
        <div class="form-group">
          <input type="text" class="form-control" mask="Hh:m0" placeholder="hh:mm" formControlName="deliveryTime"
            minlength="4" autocomplete="off">
        </div>
        
        <div *ngIf="workFormGroup.get('deliveryTime').dirty && workFormGroup.get('deliveryTime').errors?.minlength">
          <span class="error-msg">{{ 'work.time_length_invalid' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{ 'work.work_description' | translate }}</label>
          <input type="text" class="form-control" aria-label="Work Description" formControlName="description"
            maxlength="300">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label> {{ 'resource.color' | translate }}</label>
          <input type="text" class="form-control" placeholder="{{ 'resource.color' | translate }}"
            formControlName="workColor" maxlength="300" autocomplete="off">
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label>{{ 'resource.teeth' | translate }}</label>
          <input type="text" class="form-control" placeholder="{{ 'resource.teeth' | translate }}"
            formControlName="pieces" pattern="^([1-9]{2})((-|,)[1-9]{2})*$"
            (keyup)="OnTypeColorPiezasNumber(workFormGroup.get('pieces'))" autocomplete="off">
          <span class="piezas-panel piezas-results">{{ PIEZASTop | json }}</span>
          <br>
          <span class="piezas-panel piezas-results">{{ PIEZASLower| json }}</span>
        </div>
        <div *ngIf="workFormGroup.get('pieces').dirty && workFormGroup.get('pieces').errors?.pattern">
          <span class="error-msg">{{ 'work.letters_not_allowed' | translate }}</span>
        </div>
        <div *ngIf="workFormGroup.get('pieces').dirty && workFormGroup.get('pieces').errors?.invalidValue">
          <span class="error-msg">{{ 'work.letters_not_allowed' | translate }}</span>
        </div>
        <div *ngIf="workFormGroup.get('pieces').dirty && workFormGroup.get('pieces').errors?.invalidRange">
          <span class="error-msg">{{ 'work.not_allowed' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label> {{ 'resource.observations' | translate }}</label>
          <textarea rows="3" type="text" class="form-control" formControlName="clinicObservation"
            maxlength="300" autocomplete="off"></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isEdit">
      <div class="col-md-12">
        <label class="col-form-label"> {{ 'resource.document' | translate }}</label>
        
        <em (click)="fileInput2.click()" class="fa fa-paperclip attached-icon"></em>
        <div class="form-group">
            <span class="clip-style-box">
              <input id="avatar" (change)="onFileChange($event)" type="file" name="docPath" class="form-control"
                accept=".jpg, .jpeg, .png, .pdf, .avi, .mov, .mp4, .mkv, .flv, .doc, .docx, .xls, .xlsx, .gif" #fileInput2
                ng2FileSelect [uploader]="uploader" #name="ngModel" [(ngModel)]="documentPath"
                (click)="clickFileInput($event)" />
            </span>
        </div>
      </div>
      <!-- attched doc name -->
      <div class="col-md-12">
      <div class="document-list" *ngIf="docNameListOriginal && docNameListOriginal.length > 0">
        <ul>
          <li *ngFor="let doc of docNameListOriginal; let i = index">
            <div class="row">
              <div class="col-md-8">
                {{ doc }}
              </div>
              <div class="col-md-4">
                <em class="fa fa-trash float-right" (click)="removeDocument(i)"></em>
              </div>
            </div>
          </li>
        </ul>
      </div>
     </div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button type="button" class="btn btn-primary" (click)="saveWork()">{{ 'common.save' | translate }}</button>
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onCloseModal()">{{ 'common.cancel' |
    translate }}</button>
</div>

<ng-container *ngIf="isHome === 1"></ng-container>
<!-- Message Box -->
<ng-container *ngIf="(isHome === 0 && !hasProvidedWork && isOpenFromDentbox != true) ||  (hasProvidedWork  && isOpenFromDentbox)">
  <div id="conversation" class="row margin-left-right-zero">
    <!-- Chat Start -->
    <div id="no-padding" class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

          <div class="timeline-heading">

            <!-- <button class="btn btn-info btn-circle arrow-up" (click)="setPrevious()"><em
                class="glyphicon glyphicon-chevron-up"></em></button>
            <button class="btn btn-info btn-circle arrow-down" (click)="setLatest()"><em
                class="glyphicon glyphicon-chevron-down"></em></button> -->

            <!-- clinic lab  -->
            <div class="row row-lab-btns" [ngClass]="[hasProvidedWork?'row-w-max': '']">
              <div *ngIf="user_type === 'clinic'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 position-f">
                <div class="label float-clinic border-styl col-xs-6 col-sm-6 col-md-6 col-lg-6 clinic-name">
                  <ng-template #clinic> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.labCaps' | translate}} </strong>
                  </ng-template>
                  <ng-template #lab> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.clinicCaps' | translate}} </strong>
                  </ng-template>
                  <h5 *ngIf="clinicName" class="text-primary clinic-nm"
                    [ngClass]="{'clinic-name-style': selectedWork.workItemLocation === 'c'}">
                    <div class="clinic">
                      <i *ngIf="selectedWork.workItemLocation === 'c'" class="clickable" aria-hidden="true"
                        (click)="OnClickWorkItemBox('c')" [ngbPopover]="clinic" popoverClass="popover-clinic"
                        triggers="mouseenter:mouseleave"></i>
                      {{clinicName}}
                    </div>
                  </h5>
                </div>
                <div class="label lab-name col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <h5 class="text-primary lab-nm"
                    [ngClass]="{'clinic-name-style': selectedWork.workItemLocation === 'l'}">
                    <i *ngIf="selectedWork.workItemLocation === 'l'" class="clickable" aria-hidden="true"
                      (click)="OnClickWorkItemBox('l')" [ngbPopover]="lab" popoverClass="popover-lab"
                      triggers="mouseenter:mouseleave"></i> {{currentLabName}}
                  </h5>
                </div>
              </div>
              <div *ngIf="user_type === 'lab'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 position-f">
                <div class="label lab-name border-styl col-xs-6 col-sm-6 col-md-6 col-lg-6  p-r-20">
                  <ng-template #lab> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.clinicCaps' | translate}} </strong>
                  </ng-template>
                  <ng-template #clinic> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.labCaps' | translate}} </strong>
                  </ng-template>

                  <h5 *ngIf="labName" class="text-primary lab-nm2 float-right"
                    [ngClass]="{'lab-name-style': selectedWork.workItemLocation === 'l'}">

                    <i *ngIf="selectedWork.workItemLocation === 'l'" class="clickable" aria-hidden="true"
                      (click)="OnClickWorkItemBox('l')" [ngbPopover]="lab" popoverClass="popover-lab"
                      triggers="mouseenter:mouseleave"></i> {{ labName }}
                  </h5>

                </div>
                <div class="label border-styl border-styl col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <h5 class="text-primary  clinic-nm float-left labsection"
                    [ngClass]="{'clinic-name-style': selectedWork.workItemLocation === 'c'}" *ngIf="currentClinicName">
                    <i *ngIf="selectedWork.workItemLocation === 'c'" class="clickable rightside" aria-hidden="true"
                      (click)="OnClickWorkItemBox('c')" [ngbPopover]="clinic" popoverClass="popover-clinic"
                      triggers="mouseenter:mouseleave"></i> {{ currentClinicName }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- clinic lab end -->

          </div>
          <div class="timeline">
            <ng-container *ngFor="let item of uniqueDateList">
              <article class="timeline-item alt">
                <div class="text-right p-t-78">
                  <div class="time-show first">
                    <a class="btn btn-dark w-lg" *ngIf="userLanguage === 'en-US'">{{ item === currentDate ?
                      ('resource.today' | translate) : (item | date: 'dd/MM/yyyy') }}</a>
                    <a class="btn btn-dark w-lg" *ngIf="userLanguage !== 'en-US'">{{ item === currentDate ?
                      ('resource.today' | translate) : (item) }}</a>
                  </div>
                </div>
              </article>

              <ng-container *ngIf="messageList">
                <ng-container *ngFor="let message of messageList">
                  <article *ngIf="message.creationDate === item && user_type === 'clinic'" class="timeline-item"
                    [ngClass]="{'alt': message.owner === 'clinic'}">
                    <div class="timeline-desk">
                      <div class="panel">
                        <div class="panel-body padding-none-n"
                          [ngClass]="{'unSeenMessage':  message.isUnseen && message.owner === 'lab' }">
                          <span *ngIf="message === messageList[messageList.length - 1]" id="destination"></span>
                          <a *ngIf="message.owner === 'clinic' && user_type === 'clinic'"
                            [ngClass]="message.owner === 'clinic' && user_type === 'clinic' ? 'pull-left' : 'pull-right'"
                            class="delete-message m-style" (click)="confirmDelete(message._id)"
                            title="{{ 'resource.deleteMessage' | translate }}">&times;</a>
                          <span class=""
                            [ngClass]="(message.owner === 'clinic') ? 'arrow-alt' :
                            (( message.isUnseen && message.owner === 'lab') ? 'inverse-message-unseen' : 'inverse-message')">
                          </span>
                          <span class="timeline-icon"></span>
                          <div class="seen-padding">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 seen">
                              <strong>{{ message.loggedUserName ? message.loggedUserName : '' }}</strong> &nbsp;
                              <em class="fa fa-check-circle"></em>{{ message.timeDiff }}
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 datestyle">
                              <small class="seen-date-style">
                                {{ changeDateFormat(message.readDateTime) | date:'dd-MM-yyyy' }}


                              </small>
                            </div>
                          </div>
                          <div class="seen-padding-des" *ngIf="message.text.length > 0">
                            <ng-container *ngIf="message.text">
                              <ng-container *ngIf="latestMessage == message">
                                <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                  [innerHTML]="message.text | linky :{newWindow: true}">{{
                                  message.text }}</p>
                              </ng-container>
                              <ng-container *ngIf="latestMessage !== message">
                                <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                  [innerHTML]="message.text | linky :{newWindow: true}"
                                  [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">{{
                                  message.text }}</p>
                                <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                  (click)="message._id ? message._id = false : message._id = true"
                                  *ngIf="message.text.length > 80">
                                  <em *ngIf="!message._id" class="fa fa-angle-double-up" aria-hidden="true"></em>
                                  <em *ngIf="message._id" class="fa fa-angle-double-down" aria-hidden="true"></em>
                                  {{ message._id ? 'Show More' : ' Show Less'}}</button>
                              </ng-container>
                              <div class="text-right">
                                <small *ngIf="message.senderId == user_id && message.state == sent">
                                  <em class="fa fa-check check-grey"></em>
                                </small>
                                <small *ngIf="message.senderId == user_id && message.state == delivered">
                                  <em class="fa fa-check check-grey"></em> <em class="fa fa-check check-grey"></em>
                                </small>
                                <small *ngIf="message.senderId == user_id && message.state == read">
                                  <em class="fa fa-check check-blue"></em> <em class="fa fa-check check-blue"></em>
                                </small>
                              </div>

                            </ng-container>
                          </div>

                          <div *ngIf="message.attachments.length > 0" class="album attacharea">
                            <ng-container *ngFor="let item of message.attachments">
                              <span *ngIf="item" class="attachment">
                                <a *ngIf="message._id === latestMessage['_id'] && progress > 0 && progress < 99">
                                  <circle-progress [percent]="100" [radius]="40" [outerStrokeWidth]="5"
                                    [innerStrokeWidth]="5" [outerStrokeColor]="'#4ac6ff'" [innerStrokeColor]="'#94d2ef'"
                                    [animation]="true" [animationDuration]="500"></circle-progress>
                                </a>
                                <a class="show-image" *ngIf="progress === 0 || progress === 100">
                                  <img title="{{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.jpg' || item.extension === '.jpeg' || item.extension === '.png' || item.extension === '.gif' "
                                    src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}" alt="Attachment">
                                  <img title="{{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.txt' || item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"
                                    alt="Attachment" [src]="documentThumbnails">

                                  <video
                                    *ngIf="item.extension === '.mp4'|| item.extension === '.flv' || item.extension === '.avi'|| item.extension === '.mkv'|| item.extension === '.mov'"
                                    width="320" height="240" controls>
                                    <source src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}">
                                    <track label="Deutsch" kind="captions" srclang="de" hidden>
                                    Your browser does not support the video tag.
                                  </video>

                                  <div class="delete" title="{{ 'resource.removeAttachment' | translate }}">
                                    <a *ngIf="message.owner === 'clinic' && user_type === 'clinic'"
                                      (click)="confirmAttachmentDelete(message.attachments.indexOf(item), message)">
                                      <small>
                                        <em class="fa fa-times fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <div class="download">
                                    <a title="{{ 'resource.download' | translate }}"
                                      (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                      <small>
                                        <em class="fa fa-download fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <p class="text-center attachment-name">{{item.originalName }}</p>
                                </a>
                                <!--Modal for clinic-->
                                <div id="theModal-{{ item.newName.split('.')[0] }}"
                                  class="modal fade text-center attachment-popup">
                                  <div class="modal-dialog modal-lg">
                                    <div class="modal-content attachment-popup ">
                                      <div class="modal-header attachment-popup-header">
                                        <span class="text-center">{{ item.originalName }} </span>
                                      </div>
                                      <div class="modal-body attachment-popup-body">
                                        <div class="seen-padding-des seen-padding-popup-des"
                                          *ngIf="message.text.length > 0">
                                          <ng-container *ngIf="message.text">
                                            <ng-container *ngIf="latestMessage == message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}">{{
                                                message.text }}</p>
                                            </ng-container>
                                            <ng-container *ngIf="latestMessage !== message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}"
                                                [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">
                                                {{
                                                message.text }}</p>
                                              <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                                (click)="message._id ? message._id = false : message._id = true"
                                                *ngIf="message.text.length > 80">
                                                <em *ngIf="!message._id" class="fa fa-angle-double-up"
                                                  aria-hidden="true"></em>
                                                <em *ngIf="message._id" class="fa fa-angle-double-down"
                                                  aria-hidden="true"></em>
                                                {{ message._id ? 'Show More' : ' Show Less'}}</button>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                        <div class="text-center">
                                          <img
                                            *ngIf="item.extension !== '.txt' && item.extension !== '.docx' && item.extension !== '.pdf' && item.extension !== '.xlsx' && item.extension !== '.xls' && item.extension !== '.doc'"
                                            src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}"
                                            alt="Attachment {{ item.newName }}" class="img-style">
                                          <iframe title="Iframe" [src]="item.safeUrl" class="iframe-style"
                                            frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                            *ngIf="item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'">
                                          </iframe>
                                        </div>
                                       
                                      </div>
                                      <div class="modal-footer text-center m-auto">
                                        <button class="btn btn-primary"
                                          (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                          {{ 'resource.download' | translate }}
                                        </button>
                                        <button type="button" class="btn btn-secondary"
                                          data-dismiss="modal" (click)="closeModal(modal)">{{ 'common.cancel' |
                                          translate }}</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article *ngIf="message.creationDate === item && user_type === 'lab'" class="timeline-item"
                    [ngClass]="{'alt': message.owner === 'lab'}">
                    <div class="timeline-desk">
                      <div class="panel">
                        <div class="panel-body padding-none-n"
                          [ngClass]="{'unSeenMessage':  message.isUnseen && message.owner === 'clinic'}">
                          <span *ngIf="message === messageList[0]" id="destination"></span>
                          <a *ngIf="message.owner === 'lab' && user_type === 'lab'"
                            [ngClass]="message.owner === 'lab' && user_type === 'lab' ? 'pull-left' : 'pull-right'"
                            class="delete-message m-style" (click)="confirmDelete(message._id)"
                            title="{{ 'resource.deleteMessage' | translate }}">&times;</a>
                          <span class=""
                            [ngClass]="(message.owner === 'lab') ? 'arrow-alt' :
                            (( message.isUnseen && message.owner === 'clinic') ? 'inverse-message-unseen' : 'inverse-message')">
                          </span>
                          <span class="timeline-icon"></span>
                          <div class="seen-padding">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 seen">
                              <p class="text-primary">
                                <strong>{{ message.loggedUserName ? message.loggedUserName : '' }}</strong> &nbsp;
                                <em class="fa fa-check-circle"></em>{{ message.timeDiff }}
                              </p>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 datestyle">
                              <small class="seen-date-style">
                                {{ changeDateFormat(message.readDateTime) | date:'dd-MM-yyyy' }}

                              </small>
                            </div>
                          </div>

                          <ng-container *ngIf="message.text">
                            <div class="seen-padding-des" *ngIf="message.text.length > 0">
                              <ng-container *ngIf="message.text">
                                <ng-container *ngIf="latestMessage == message">
                                  <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                    [innerHTML]="message.text | linky :{newWindow: true}">{{
                                    message.text }}</p>
                                </ng-container>
                                <ng-container *ngIf="latestMessage !== message">
                                  <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                    [innerHTML]="message.text | linky :{newWindow: true}"
                                    [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">{{
                                    message.text }}</p>
                                  <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                    (click)="message._id ? message._id = false : message._id = true"
                                    *ngIf="message.text.length > 80">
                                    <em *ngIf="!message._id" class="fa fa-angle-double-up" aria-hidden="true"></em>
                                    <em *ngIf="message._id" class="fa fa-angle-double-down" aria-hidden="true"></em>
                                    {{ message._id ? 'Show More' : ' Show Less'}}</button>
                                </ng-container>
                                <div class="text-right">
                                  <small *ngIf="message.senderId == user_id && message.state == sent">
                                    <em class="fa fa-check check-grey"></em>
                                  </small>
                                  <small *ngIf="message.senderId == user_id && message.state == delivered">
                                    <em class="fa fa-check check-grey"></em> <em class="fa fa-check check-grey"></em>
                                  </small>
                                  <small *ngIf="message.senderId == user_id && message.state == read">
                                    <em class="fa fa-check check-blue"></em> <em class="fa fa-check check-blue"></em>
                                  </small>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>

                          <div *ngIf="message.attachments.length > 0" class="album attacharea">
                            <ng-container *ngFor="let item of message.attachments">
                              <span *ngIf="item" class="attachment">

                                <a class="show-image" *ngIf="progress === 0 || progress === 100">
                                  <img title="{{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.jpg' || item.extension === '.jpeg' || item.extension === '.png' || item.extension === '.gif'"
                                    src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}" alt="">
                                  <img title="
                                    {{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.txt' || item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"
                                    alt="Resource Preview Attach" [src]="documentThumbnails">

                                  <video
                                    *ngIf="item.extension === '.mp4'|| item.extension === '.flv' || item.extension === '.avi'|| item.extension === '.mkv'|| item.extension === '.mov'"
                                    width="320" height="240" controls>
                                    <source src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}">
                                    <track label="Deutsch" kind="captions" srclang="de" hidden>
                                    Your browser does not support the video tag.
                                  </video>

                                  <div class="delete" title="{{ 'resource.removeAttachment' | translate }}">
                                    <a *ngIf="message.owner === 'lab' && user_type === 'lab'"
                                      (click)="confirmAttachmentDelete(message.attachments.indexOf(item), message)">
                                      <small>
                                        <em class="fa fa-times fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <div class="download">
                                    <a title="{{ 'resource.download' | translate }}"
                                      (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                      <small>
                                        <em class="fa fa-download fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <p class="text-center attachment-name">{{item.originalName }}</p>
                                </a>
                                <!--Modal for lab-->
                                <div id="theModal-{{ item.newName.split('.')[0] }}"
                                  class="modal fade text-center attachment-popup">
                                  <div class="modal-dialog modal-lg">
                                    <div class="modal-content attachment-popup">
                                      <div class="modal-header attachment-popup-header">
                                        <span class="text-center">{{ item.originalName }}
                                          <button class=" pull-left btn btn-xs btn-default"
                                            (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                            {{ 'resource.download' | translate }}
                                            <em class="fa fa-angle-double-down" aria-hidden="true"></em>
                                          </button>
                                          <button type="button pull-right" class="close"
                                            data-dismiss="modal">&times;</button>
                                        </span>
                                      </div>
                                      <div class="modal-body attachment-popup-body">
                                        <div class="seen-padding-des seen-padding-popup-des"
                                          *ngIf="message.text.length > 0">
                                          <ng-container *ngIf="message.text">
                                            <ng-container *ngIf="latestMessage == message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}">{{
                                                message.text }}</p>
                                            </ng-container>
                                            <ng-container *ngIf="latestMessage !== message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}"
                                                [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">
                                                {{
                                                message.text }}</p>
                                              <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                                (click)="message._id ? message._id = false : message._id = true"
                                                *ngIf="message.text.length > 80">
                                                <em *ngIf="!message._id" class="fa fa-angle-double-up"
                                                  aria-hidden="true"></em>
                                                <em *ngIf="message._id" class="fa fa-angle-double-down"
                                                  aria-hidden="true"></em>
                                                {{ message._id ? 'Show More' : ' Show Less'}}</button>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                        <div class="text-center">
                                          <img
                                            *ngIf="item.extension !== '.txt' && item.extension !== '.docx' && item.extension !== '.pdf' && item.extension !== '.xlsx' && item.extension !== '.xls' && item.extension !== '.doc'"
                                            src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}" alt="
                                            Attachment {{ item.newName }}" class="img-style">
                                          <iframe title="Iframe" [src]="item.safeUrl" class="iframe-style"
                                            frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                            *ngIf="item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"></iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat End -->
    <div id="loading-icon" class="chat-end-style" [hidden]="!loading">
      <img src="../../../../assets/images/Loading.gif" width="70px" height="70px" alt="Loading Pic">
    </div>

    <!-- Reply Box -->
    <div class="row">
      <div class="reply" [ngClass]="[hasProvidedWork ? 'reply-w-max': '']">
        <div class="col-sm-7 col-xs-9 col-md-9 col-lg-9 reply-main">
          <textarea [(ngModel)]="messageString" (keydown.enter)="sendReply()" (keydown.enter)="$event.preventDefault()"
            (focus)="updateMessageStatus(read)" rows="1" id="comment" class="form-control txt-bg"
            placeholder="{{ 'resource.message' | translate }}"></textarea>
        </div>
        <div class="col-sm-5 col-xs-3 col-md-3 col-lg-3">
          <div class="clip-style">
            <em (click)="fileInput.click()" class="fa fa-paperclip fa-2x flip-position"></em>
            <span class="clip-style-box">
              <input id="avatar" (change)="onFileChange($event)" type="file" name="docPath"
                accept=".jpg, .png, .pdf, .avi, .mov, .mp4, .mkv, .flv, .doc, .docx, .xls, .xlsx, .gif" #fileInput
                ng2FileSelect [uploader]="uploader" #name="ngModel" [(ngModel)]="documentPath"
                (click)="clickFileInput($event)" />
            </span>
          </div>
          <div class="send-btn-chat">
            <button type="button" class="btn btn-primary reply-send msg-para" (click)="sendReply()" ngxScrollTo
              [ngxScrollToOffset]="5000">
              <div class="">
                <p class="text-center txt-white"><em class="fa fa-send" aria-hidden="true"></em>{{ 'resource.send' |
                  translate }}</p>
              </div>

            </button>
          </div>
        </div>
        <div *ngIf="docNameList.length > 0" class="row">
          <div class="col-md-12 dropper ">
            <ng-container *ngFor="let item of docNameList">
              <div class="attach">
                <img class="dropper-item" *ngIf="item" [src]="documentThumbnails" title="{{item}}" alt="Attachment">
                <div class="delete" title="Remove attachment">
                  <a (click)="onRemoveAttachment(item)">
                    <small><em class="fa fa-times fa-lg"></em>
                    </small>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- Reply Box End -->
  </div>
</ng-container>
<!-- Message Box End -->

<!-- Novalab work page chat panel open div -->
<ng-container *ngIf="isHome === 0 && hasProvidedWork && isOpenFromDentbox != true ">
  <div id="conversation" class="row margin-left-right-zero novalab-work-dentbox-chat">
    <!-- Chat Start -->
    <div id="no-padding" class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

          <div class="timeline-heading">

            <!-- <button class="btn btn-info btn-circle arrow-up" (click)="setPrevious()"><em
                class="glyphicon glyphicon-chevron-up"></em></button>
            <button class="btn btn-info btn-circle arrow-down" (click)="setLatest()"><em
                class="glyphicon glyphicon-chevron-down"></em></button> -->

            <!-- clinic lab  -->
            <div class="row row-lab-btns" [ngClass]="[hasProvidedWork?'row-w-max': '']">
              <div *ngIf="user_type === 'clinic'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 position-f">
                <div class="label float-clinic border-styl col-xs-6 col-sm-6 col-md-6 col-lg-6 clinic-name">
                  <ng-template #clinic> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.labCaps' | translate}} </strong>
                  </ng-template>
                  <ng-template #lab> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.clinicCaps' | translate}} </strong>
                  </ng-template>
                  <h5 *ngIf="clinicName" class="text-primary clinic-nm"
                    [ngClass]="{'clinic-name-style': selectedWork.workItemLocation === 'c'}">
                    <div class="clinic">
                      <i *ngIf="selectedWork.workItemLocation === 'c'" class="clickable" aria-hidden="true"
                        (click)="OnClickWorkItemBox('c')" [ngbPopover]="clinic" popoverClass="popover-clinic"
                        triggers="mouseenter:mouseleave"></i>
                      {{clinicName}}
                    </div>
                  </h5>
                </div>
                <div class="label lab-name col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <h5 class="text-primary lab-nm"
                    [ngClass]="{'clinic-name-style': selectedWork.workItemLocation === 'l'}">
                    <i *ngIf="selectedWork.workItemLocation === 'l'" class="clickable" aria-hidden="true"
                      (click)="OnClickWorkItemBox('l')" [ngbPopover]="lab" popoverClass="popover-lab"
                      triggers="mouseenter:mouseleave"></i> {{currentLabName}}
                  </h5>
                </div>
              </div>
              <div *ngIf="user_type === 'lab'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 position-f">
                <div class="label lab-name border-styl col-xs-6 col-sm-6 col-md-6 col-lg-6  p-r-20">
                  <ng-template #lab> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.clinicCaps' | translate}} </strong>
                  </ng-template>
                  <ng-template #clinic> {{'resource.pressToMove' | translate}} <br />
                    <strong> {{'resource.in' | translate}} {{'resource.labCaps' | translate}} </strong>
                  </ng-template>

                  <h5 *ngIf="labName" class="text-primary lab-nm2 float-right"
                    [ngClass]="{'lab-name-style': selectedWork.workItemLocation === 'l'}">

                    <i *ngIf="selectedWork.workItemLocation === 'l'" class="clickable" aria-hidden="true"
                      (click)="OnClickWorkItemBox('l')" [ngbPopover]="lab" popoverClass="popover-lab"
                      triggers="mouseenter:mouseleave"></i> {{ labName }}
                  </h5>

                </div>
                <div class="label border-styl border-styl col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <h5 class="text-primary  clinic-nm float-left labsection"
                    [ngClass]="{'clinic-name-style': selectedWork.workItemLocation === 'c'}" *ngIf="currentClinicName">
                    <i *ngIf="selectedWork.workItemLocation === 'c'" class="clickable rightside" aria-hidden="true"
                      (click)="OnClickWorkItemBox('c')" [ngbPopover]="clinic" popoverClass="popover-clinic"
                      triggers="mouseenter:mouseleave"></i> {{ currentClinicName }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- clinic lab end -->

          </div>
          <div class="timeline">
            <ng-container *ngFor="let item of uniqueDateList">
              <article class="timeline-item alt">
                <div class="text-right p-t-78">
                  <div class="time-show timeline-container first">
                    <div class="timeline-line"></div>
                    <a class="btn btn-dark w-lg date-item" *ngIf="userLanguage === 'en-US'">{{ item === currentDate ?
                      ('resource.today' | translate) : (item | date: 'dd/MM/yyyy') }}</a>
                    <a class="btn btn-dark w-lg date-item" *ngIf="userLanguage !== 'en-US'">{{ item === currentDate ?
                      ('resource.today' | translate) : (item) }}</a>
                  </div>
                </div>
              </article>

              <ng-container *ngIf="messageList">
                <ng-container *ngFor="let message of messageList">
                  <article *ngIf="message.creationDate === item && user_type === 'clinic'" class="timeline-item"
                    [ngClass]="{'alt': message.owner === 'clinic'}">
                    <div class="timeline-desk">
                      <div class="panel">
                        <div class="panel-body padding-none-n"
                          [ngClass]="{'unSeenMessage':  message.isUnseen && message.owner === 'lab' }">
                          <span *ngIf="message === messageList[messageList.length - 1]" id="destination"></span>
                          <a *ngIf="message.owner === 'clinic' && user_type === 'clinic'"
                            [ngClass]="message.owner === 'clinic' && user_type === 'clinic' ? 'pull-left' : 'pull-right'"
                            class="delete-message m-style" (click)="confirmDelete(message._id)"
                            title="{{ 'resource.deleteMessage' | translate }}">&times;</a>
                          <span class=""
                            [ngClass]="(message.owner === 'clinic') ? 'arrow-alt' :
                            (( message.isUnseen && message.owner === 'lab') ? 'inverse-message-unseen' : 'inverse-message')">
                          </span>
                          <span class="timeline-icon"></span>
                          <div class="seen-padding">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 seen">
                              <strong>{{ message.loggedUserName ? message.loggedUserName : '' }}</strong> &nbsp;
                              <em class="fa fa-check-circle"></em>{{ message.timeDiff }}
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 datestyle">
                              <small class="seen-date-style">
                                {{ changeDateFormat(message.readDateTime) | date:'dd-MM-yyyy' }}


                              </small>
                            </div>
                          </div>
                          <div class="seen-padding-des" *ngIf="message.text.length > 0">
                            <ng-container *ngIf="message.text">
                              <ng-container *ngIf="latestMessage == message">
                                <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                  [innerHTML]="message.text | linky :{newWindow: true}">{{
                                  message.text }}</p>
                              </ng-container>
                              <ng-container *ngIf="latestMessage !== message">
                                <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                  [innerHTML]="message.text | linky :{newWindow: true}"
                                  [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">{{
                                  message.text }}</p>
                                <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                  (click)="message._id ? message._id = false : message._id = true"
                                  *ngIf="message.text.length > 80">
                                  <em *ngIf="!message._id" class="fa fa-angle-double-up" aria-hidden="true"></em>
                                  <em *ngIf="message._id" class="fa fa-angle-double-down" aria-hidden="true"></em>
                                  {{ message._id ? 'Show More' : ' Show Less'}}</button>
                              </ng-container>
                              <div class="text-right">
                                <small *ngIf="message.senderId == user_id && message.state == sent">
                                  <em class="fa fa-check check-grey"></em>
                                </small>
                                <small *ngIf="message.senderId == user_id && message.state == delivered">
                                  <em class="fa fa-check check-grey"></em> <em class="fa fa-check check-grey"></em>
                                </small>
                                <small *ngIf="message.senderId == user_id && message.state == read">
                                  <em class="fa fa-check check-blue"></em> <em class="fa fa-check check-blue"></em>
                                </small>
                              </div>

                            </ng-container>
                          </div>

                          <div *ngIf="message.attachments.length > 0" class="album attacharea">
                            <ng-container *ngFor="let item of message.attachments">
                              <span *ngIf="item" class="attachment">
                                <a *ngIf="message._id === latestMessage['_id'] && progress > 0 && progress < 99">
                                  <circle-progress [percent]="100" [radius]="40" [outerStrokeWidth]="5"
                                    [innerStrokeWidth]="5" [outerStrokeColor]="'#4ac6ff'" [innerStrokeColor]="'#94d2ef'"
                                    [animation]="true" [animationDuration]="500"></circle-progress>
                                </a>
                                <a class="show-image" *ngIf="progress === 0 || progress === 100">
                                  <img title="{{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.jpg' || item.extension === '.jpeg' || item.extension === '.png' || item.extension === '.gif' "
                                    src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}" alt="Attachment">
                                  <img title="{{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.txt' || item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"
                                    alt="Attachment" [src]="documentThumbnails">

                                  <video
                                    *ngIf="item.extension === '.mp4'|| item.extension === '.flv' || item.extension === '.avi'|| item.extension === '.mkv'|| item.extension === '.mov'"
                                    width="320" height="240" controls>
                                    <source src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}">
                                    <track label="Deutsch" kind="captions" srclang="de" hidden>
                                    Your browser does not support the video tag.
                                  </video>

                                  <div class="delete" title="{{ 'resource.removeAttachment' | translate }}">
                                    <a *ngIf="message.owner === 'clinic' && user_type === 'clinic'"
                                      (click)="confirmAttachmentDelete(message.attachments.indexOf(item), message)">
                                      <small>
                                        <em class="fa fa-times fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <div class="download">
                                    <a title="{{ 'resource.download' | translate }}"
                                      (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                      <small>
                                        <em class="fa fa-download fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <p class="text-center attachment-name">{{item.originalName }}</p>
                                </a>
                                <!--Modal for clinic-->
                                <div id="theModal-{{ item.newName.split('.')[0] }}"
                                  class="modal fade text-center attachment-popup">
                                  <div class="modal-dialog modal-lg">
                                    <div class="modal-content attachment-popup">
                                      <div class="modal-header attachment-popup-header">
                                        <span class="text-center">{{ item.originalName }}
                                          <button class=" pull-left btn btn-xs btn-default"
                                            (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                            {{ 'resource.download' | translate }}
                                            <em class="fa fa-angle-double-down" aria-hidden="true"></em>
                                          </button>
                                          <button type="button pull-right" class="close"
                                            data-dismiss="modal">&times;</button>
                                        </span>
                                      </div>
                                      <div class="modal-body attachment-popup-body">
                                        <div class="seen-padding-des seen-padding-popup-des"
                                          *ngIf="message.text.length > 0">
                                          <ng-container *ngIf="message.text">
                                            <ng-container *ngIf="latestMessage == message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}">{{
                                                message.text }}</p>
                                            </ng-container>
                                            <ng-container *ngIf="latestMessage !== message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}"
                                                [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">
                                                {{
                                                message.text }}</p>
                                              <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                                (click)="message._id ? message._id = false : message._id = true"
                                                *ngIf="message.text.length > 80">
                                                <em *ngIf="!message._id" class="fa fa-angle-double-up"
                                                  aria-hidden="true"></em>
                                                <em *ngIf="message._id" class="fa fa-angle-double-down"
                                                  aria-hidden="true"></em>
                                                {{ message._id ? 'Show More' : ' Show Less'}}</button>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                        <div class="text-center">
                                          <img
                                            *ngIf="item.extension !== '.txt' && item.extension !== '.docx' && item.extension !== '.pdf' && item.extension !== '.xlsx' && item.extension !== '.xls' && item.extension !== '.doc'"
                                            src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}"
                                            alt="Attachment {{ item.newName }}" class="img-style">
                                          <iframe title="Iframe" [src]="item.safeUrl" class="iframe-style"
                                            frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                            *ngIf="item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'">
                                          </iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article *ngIf="message.creationDate === item && user_type === 'lab'" class="timeline-item"
                    [ngClass]="{'alt': message.owner === 'lab'}">
                    <div class="timeline-desk">
                      <div class="panel">
                        <div class="panel-body padding-none-n"
                          [ngClass]="{'unSeenMessage':  message.isUnseen && message.owner === 'clinic'}">
                          <span *ngIf="message === messageList[0]" id="destination"></span>
                          <a *ngIf="message.owner === 'lab' && user_type === 'lab'"
                            [ngClass]="message.owner === 'lab' && user_type === 'lab' ? 'pull-left' : 'pull-right'"
                            class="delete-message m-style" (click)="confirmDelete(message._id)"
                            title="{{ 'resource.deleteMessage' | translate }}">&times;</a>
                          <span class=""
                            [ngClass]="(message.owner === 'lab') ? 'arrow-alt' :
                            (( message.isUnseen && message.owner === 'clinic') ? 'inverse-message-unseen' : 'inverse-message')">
                          </span>
                          <span class="timeline-icon"></span>
                          <div class="seen-padding">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 seen">
                              <p class="text-primary">
                                <strong>{{ message.loggedUserName ? message.loggedUserName : '' }}</strong> &nbsp;
                                <em class="fa fa-check-circle"></em>{{ message.timeDiff }}
                              </p>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 datestyle">
                              <small class="seen-date-style">
                                {{ changeDateFormat(message.readDateTime) | date:'dd-MM-yyyy' }}

                              </small>
                            </div>
                          </div>

                          <ng-container *ngIf="message.text">
                            <div class="seen-padding-des" *ngIf="message.text.length > 0">
                              <ng-container *ngIf="message.text">
                                <ng-container *ngIf="latestMessage == message">
                                  <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                    [innerHTML]="message.text | linky :{newWindow: true}">{{
                                    message.text }}</p>
                                </ng-container>
                                <ng-container *ngIf="latestMessage !== message">
                                  <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                    [innerHTML]="message.text | linky :{newWindow: true}"
                                    [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">{{
                                    message.text }}</p>
                                  <button id="{{message._id}}" class="btn btn-xs btn-show-more"
                                    (click)="message._id ? message._id = false : message._id = true"
                                    *ngIf="message.text.length > 80">
                                    <em *ngIf="!message._id" class="fa fa-angle-double-up" aria-hidden="true"></em>
                                    <em *ngIf="message._id" class="fa fa-angle-double-down" aria-hidden="true"></em>
                                    {{ message._id ? 'Show More' : ' Show Less'}}</button>
                                </ng-container>
                                <div class="text-right">
                                  <small *ngIf="message.senderId == user_id && message.state == sent">
                                    <em class="fa fa-check check-grey"></em>
                                  </small>
                                  <small *ngIf="message.senderId == user_id && message.state == delivered">
                                    <em class="fa fa-check check-grey"></em> <em class="fa fa-check check-grey"></em>
                                  </small>
                                  <small *ngIf="message.senderId == user_id && message.state == read">
                                    <em class="fa fa-check check-blue"></em> <em class="fa fa-check check-blue"></em>
                                  </small>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>

                          <div *ngIf="message.attachments.length > 0" class="album attacharea">
                            <ng-container *ngFor="let item of message.attachments">
                              <span *ngIf="item" class="attachment">

                                <a class="show-image" *ngIf="progress === 0 || progress === 100">
                                  <img title="{{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.jpg' || item.extension === '.jpeg' || item.extension === '.png' || item.extension === '.gif'"
                                    src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}" alt="">
                                  <img title="
                                    {{ 'resource.previewAttach' | translate }}" data-toggle="modal" href="#"
                                    (click)="onOpenModal(message)"
                                    [attr.data-target]="'#theModal-' + item.newName.split('.')[0]"
                                    *ngIf="item.extension === '.txt' || item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"
                                    alt="Resource Preview Attach" [src]="documentThumbnails">

                                  <video
                                    *ngIf="item.extension === '.mp4'|| item.extension === '.flv' || item.extension === '.avi'|| item.extension === '.mkv'|| item.extension === '.mov'"
                                    width="320" height="240" controls>
                                    <source src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}">
                                    <track label="Deutsch" kind="captions" srclang="de" hidden>
                                    Your browser does not support the video tag.
                                  </video>

                                  <div class="delete" title="{{ 'resource.removeAttachment' | translate }}">
                                    <a *ngIf="message.owner === 'lab' && user_type === 'lab'"
                                      (click)="confirmAttachmentDelete(message.attachments.indexOf(item), message)">
                                      <small>
                                        <em class="fa fa-times fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <div class="download">
                                    <a title="{{ 'resource.download' | translate }}"
                                      (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                      <small>
                                        <em class="fa fa-download fa-lg"></em>
                                      </small>
                                    </a>
                                  </div>

                                  <p class="text-center attachment-name">{{item.originalName }}</p>
                                </a>
                                <!--Modal for lab-->
                                <div id="theModal-{{ item.newName.split('.')[0] }}"
                                  class="modal fade text-center attachment-popup">
                                  <div class="modal-dialog modal-lg">
                                    <div class="modal-content attachment-popup">
                                      <div class="modal-header attachment-popup-header">
                                        <span class="text-center">{{ item.originalName }}
                                          <button class=" pull-left btn btn-xs btn-default"
                                            (click)="download(FILE_SERVER_URL + message.workId + '/' + item.url, item.originalName)">
                                            {{ 'resource.download' | translate }}
                                            <em class="fa fa-angle-double-down" aria-hidden="true"></em>
                                          </button>
                                          <button type="button pull-right" class="close"
                                            data-dismiss="modal">&times;</button>
                                        </span>
                                      </div>
                                      <div class="modal-body attachment-popup-body">
                                        <div class="seen-padding-des seen-padding-popup-des"
                                          *ngIf="message.text.length > 0">
                                          <ng-container *ngIf="message.text">
                                            <ng-container *ngIf="latestMessage == message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}">{{
                                                message.text }}</p>
                                            </ng-container>
                                            <ng-container *ngIf="latestMessage !== message">
                                              <p id="text-{{message._id}}" class="angular-with-newlines p-style-new"
                                                [innerHTML]="message.text | linky :{newWindow: true}"
                                                [ngClass]="(message.text.length) > 80 && message._id ? 'hide-text': 'show'">
                                                {{
                                                message.text }}</p>
                                              <button id="{{message._id}}" class="btn btn-xs "
                                                (click)="message._id ? message._id = false : message._id = true"
                                                *ngIf="message.text.length > 80">
                                                <em *ngIf="!message._id" class="fa fa-angle-double-up"
                                                  aria-hidden="true"></em>
                                                <em *ngIf="message._id" class="fa fa-angle-double-down"
                                                  aria-hidden="true"></em>
                                                {{ message._id ? 'Show More' : ' Show Less'}}</button>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                        <div class="text-center">
                                          <img
                                            *ngIf="item.extension !== '.txt' && item.extension !== '.docx' && item.extension !== '.pdf' && item.extension !== '.xlsx' && item.extension !== '.xls' && item.extension !== '.doc'"
                                            src="{{ FILE_SERVER_URL + message.workId + '/' + item.url }}" alt="
                                            Attachment {{ item.newName }}" class="img-style">
                                          <iframe title="Iframe" [src]="item.safeUrl" class="iframe-style"
                                            frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                            *ngIf="item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"></iframe>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat End -->
    <div id="loading-icon" class="chat-end-style" [hidden]="!loading">
      <img src="../../../../assets/images/Loading.gif" width="70px" height="70px" alt="Loading Pic">
    </div>

    <!-- Reply Box -->
    <div class="row">
      <div class="reply" [ngClass]="[hasProvidedWork ? 'reply-w-max': '']">
        <div class="col-sm-7 col-xs-8 col-md-9 col-lg-9 reply-main">
          <textarea [(ngModel)]="messageString" (keydown.enter)="sendReply()" (keydown.enter)="$event.preventDefault()"
            (focus)="updateMessageStatus(read)" rows="1" id="comment" class="form-control txt-bg mt-2"
            placeholder="{{ 'resource.message' | translate }}"></textarea>
        </div>
        <div class="col-sm-5 col-xs-4 col-md-3 col-lg-3 p-xs-0">
          <div class="clip-style">
            <em (click)="fileInput.click()" class="fa fa-paperclip fa-2x flip-position"></em>
            <span class="clip-style-box">
              <input id="avatar" (change)="onFileChange($event)" type="file" name="docPath"
                accept=".jpg, .png, .pdf, .avi, .mov, .mp4, .mkv, .flv, .doc, .docx, .xls, .xlsx, .gif" #fileInput
                ng2FileSelect [uploader]="uploader" #name="ngModel" [(ngModel)]="documentPath"
                (click)="clickFileInput($event)" />
            </span>
          </div>
          <div class="send-btn-chat mt-2">
            <button type="button" class="btn btn-primary reply-send msg-para" (click)="sendReply()" ngxScrollTo
              [ngxScrollToOffset]="5000">
              <div class="">
                <p class="text-center txt-white"><em class="fa fa-send" aria-hidden="true"></em>{{ 'resource.send' |
                  translate }}</p>
              </div>

            </button>
          </div>
        </div>
        <div *ngIf="docNameList.length > 0" class="row">
          <div class="col-md-12 dropper ">
            <ng-container *ngFor="let item of docNameList">
              <div class="attach">
                <img class="dropper-item" *ngIf="item" [src]="documentThumbnails" title="{{item}}" alt="Attachment">
                <div class="delete" title="Remove attachment">
                  <a (click)="onRemoveAttachment(item)">
                    <small><em class="fa fa-times fa-lg"></em>
                    </small>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- Reply Box End -->
  </div>
</ng-container>
<!-- / Novalab work page chat panel open div -->

<!-- add preloader  -->
<div class="preloader-wrapper" *ngIf="isHome === 1">
  <header>
    <div *ngIf="preloader" aria-busy="true" aria-label="Loading, please wait." role="progressbar"></div>
  </header>
</div>

import { Subscription } from 'rxjs';
import { ChatService } from './../../../service/chat.service';
import { Component, OnInit } from '@angular/core';
import { LabService } from '../../../service/lab.service';
import { ClinicService } from '../../../service/clinic.service';
import { TranslateService } from '@ngx-translate/core';
import { Clinic } from '../../../model/clinic';
import { Lab } from '../../../model/lab';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { UploadService } from '../../../service/upload.service';
import { ApiConnection } from '../../../service/apiConnection';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';

const URL = ApiConnection.BASE_URL + ApiConnection.API_VERSION;

@Component({
  selector: 'chat-leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss']
})
export class LeftbarComponent implements OnInit {

  public localImageUrl: SafeUrl;
  public _onAdd: boolean;
  public clinic: Clinic;
  public lab: Lab;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'profileImage' });
  public user: any;
  public usertype;
  public UserForm: UntypedFormGroup;
  public userForm: NgForm;
  public selectedFileList: FileList;
  public _anotherUpload: boolean;
  public _anotherUpload2: boolean;
  public _anotherUpload3: boolean;
  public logoPathOnEdit: string;
  public FileNameOnEdit: string;
  public translateLabels: string[] = [];
  private subscriber: Subscription;

  constructor(private translate: TranslateService,
    private _labService: LabService,
    private _clinicService: ClinicService,
    public sanitizer: DomSanitizer,
    private _fb: UntypedFormBuilder,
    private _uploadService: UploadService,
    private _toast: ToastrService,
    private _chatService: ChatService
  ) {
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.localImageUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this._onAdd = false;
    };
  }

  ngOnInit() {
    this.subscriber = this._chatService.translateLabel
      .subscribe(translateLabel => this.translateLabels = translateLabel);

    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    if (data) {
      this.user = JSON.parse(data);
      if (this.user.language) {
        this.translate.use(this.user.language);
      } else {
        const language = this.translate.getBrowserLang();
        this.user.language = language;
        this.translate.setDefaultLang(
          language ? language : ApiConnection.DEFAULT_LANGUAGE);
      }
    }

    this.usertype = window.sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    if (this.usertype === 'clinic') {
      this._clinicService.selectedUser = Object.assign({}, this.user as Clinic);
      this.clinic = this._clinicService.selectedUser;
    } else if (this.usertype === 'lab') {
      this._labService.selectedUser = Object.assign({}, this.user as Lab);
      this.lab = this._labService.selectedUser;
    }
    this._anotherUpload = false;
    this._anotherUpload2 = true;
    this._anotherUpload3 = false;
    this._onAdd = true;
    this.UserForm = this._fb.group({
      _id: ['', []],
      logoPath: ['', []]
    });

    this._chatService.setLabels();
  }

  get profileImage() {
    return this.UserForm.get('logoPath');
  }

  public anotherUpload(lPathOnEdit: string) {
    this._anotherUpload = true;
    this.logoPathOnEdit = lPathOnEdit;
    if (lPathOnEdit === undefined) {
      this.FileNameOnEdit = '';
    } else {
      const arr = this.logoPathOnEdit.split('/');
      this.FileNameOnEdit = arr[arr.length - 1];
    }
  }

  public onFileChange(event, lPathOnEdit: string) {
    if (event) {
      this.selectedFileList = event.target.files;
      this.anotherUpload(lPathOnEdit);
    }
  }

  public uploadFile(newName: string) {
    if (this.selectedFileList === undefined) {
      console.log('No file selected!');
    } else {
      for (let i = 0; i < this.selectedFileList.length; i++) {
        const file: File = this.selectedFileList[i];
        this._uploadService.uploadProfilePicture(ApiConnection.FILE_SERVER_URL + ApiConnection.FILE_VERSION + 'uploadProfilePicture', file, this.usertype, newName)
          .subscribe(
            event => {
              if (event.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round(100 * event.loaded / event.total);
                console.log(`File is ${percentDone}% loaded.`);
              } else if (event instanceof HttpResponse) {
                console.log('File is completely loaded!');
                location.reload();
              }
            },
            (err) => {
              console.log('Upload Error:', err);
            }, () => {
              console.log('Upload done.');
            }
          );
      }
    }
  }

  public async changeLanguage(language: string): Promise<void> {
    if (language) {
      sessionStorage.setItem('currentLang', language); // save language in session storage
      this.translate.use(language);
      if (this.usertype === 'lab') {
        this.user.language = language;
        this._labService.putLab(this.user as Lab).subscribe((res => {
        }));
      } else if (this.usertype === 'clinic') {
        this.user.language = language;
        this._clinicService.putClinic(this.user as Clinic).subscribe((res => {
        }));
      }
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
    }
    this._chatService.setLabels();
  }

  public exitUpdate() {
    this._anotherUpload = false;
    this.localImageUrl = false;
  }

  public onSave(UserForm: NgForm, lPath?: string) {
    let arr2;
    if (this.selectedFileList.length > 0) {
      let fileName = (this.selectedFileList[0]).name;
      const arrextn = fileName.split('.');
      let extn = arrextn[arrextn.length - 1];
      extn = extn.toLowerCase();
      if ((extn === 'jpg') || (extn === 'jpeg') || (extn === 'png')) {
        if (fileName === '' && this._anotherUpload) {
          fileName = this.FileNameOnEdit;
        } else if ((fileName === '') && (lPath === undefined)) {
          fileName = '';
        } else if (fileName === '') {
          this.logoPathOnEdit = lPath;
          arr2 = this.logoPathOnEdit.split('/');
          this.FileNameOnEdit = arr2[arr2.length - 1];
          fileName = this.FileNameOnEdit;
        }
        const fileExtension = '.' + fileName.split('.').pop();
        const file_name: string = Math.random().toString(36).substring(7) + new Date().getTime() + fileExtension;
        const path = ApiConnection.FILE_SERVER_URL + '/' + file_name;
        if (fileName !== '') {
          UserForm.value.logoPath = path;
        } else {
          UserForm.value.logoPath = '';
        }

        try {
          UserForm.value.logoPath = path;

          if (fileName !== '') {
            UserForm.value.logoPath = path;
          } else {
            UserForm.value.logoPath = '';
          }
          this.user.logoPath = UserForm.value.logoPath;
          if (this.usertype === 'clinic') {
            this.user.logoPath = UserForm.value.logoPath;
            this._clinicService.putClinic(this.user as Clinic).subscribe((res => {
              sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
              this.uploadFile(file_name);
              this.exitUpdate();
              this._toast.success('', this.translateLabels['profileUpdated']);
            }));
          } else if (this.usertype === 'lab') {
            this.user.logoPath = UserForm.value.logoPath;
            this._labService.putLab(this.user as Lab).subscribe((res => {
              sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
              this.uploadFile(file_name);
              this.exitUpdate();
              this._toast.success('', this.translateLabels['profileUpdated']);
            }));
          }
        } catch {
          (error: Response) => { this._toast.error('', error + ''); };
        }
      } else {
        this._toast.error('', 'Invalid file format !');
      }
    }
  }

  public removeImage(user: any) {
    if (this.usertype === 'clinic') {
      this.user.logoPath = '';
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(user));
      this._clinicService.putClinic(user).subscribe((res => {
        this.exitUpdate();
        location.reload();
        // this._toast.success('',this.translateLabels['profileUpdated']);
      }));
    } else if (this.usertype === 'lab') {
      this.user.logoPath = '';
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(user));
      this._labService.putLab(user).subscribe((res => {
        this.exitUpdate();
        location.reload();
      }));
    }
  }

  public getFirstTwoLetters(patientName: string) {
    if (patientName) {
      const arr = patientName.split(' ');
      if (arr.length > 1) {
        return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
      } else {
        return (arr[0].substring(0, 2)).toUpperCase();
      }
    }
  }

  changePatientPrivacy(state: boolean) {
    this.user.isHidePatientInfo = state;
    this._clinicService.putClinic(this.user as Clinic).subscribe((res => {
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
    }));
  }

  changeJobEditPermission(state: boolean) {
    this.user.isEnableJobEdit = state;
    this._clinicService.putClinic(this.user as Clinic).subscribe((res => {
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
    }));
  }

  changeJobDeletePermission(state: boolean) {
    this.user.isEnableJobDelete = state;
    this._clinicService.putClinic(this.user as Clinic).subscribe((res => {
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
    }));
  }
}

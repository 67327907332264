import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '../../../service/chat.service';
import { Work } from '../../../model/work';
import { Lab } from '../../../model/lab';
import { Clinic } from '../../../model/clinic';
import { MessageService } from '../../../service/message.service';
import { DatePipe } from '@angular/common';
import { SocketService } from '../../../service/sockets.service';
import { Message } from '../../../model/message';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterModel } from '../../../model/filter-model';
import { DateFormat } from '../../../model/date-format';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddWorkComponent } from '../add-work/add-work.component';
import { Subscription } from 'rxjs';
import { WorkService } from 'src/app/service/work.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CommonUiService } from 'src/app/service/common.ui.service';
import * as moment from 'moment';
import * as unidecode from 'unidecode';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'chat-side-bar',
  providers: [],
  templateUrl: './chat-side-bar.component.html',
  styleUrls: ['./chat-side-bar.component.scss'],
})

export class ChatSideBarComponent implements OnInit, AfterViewChecked {

  @ViewChild('stateListDivElement') linkRefs: ElementRef;

  private _workList: any[] = [];
  private _unSeenMessageList: any[] = [];
  private _latestMessage: Message;

  public workList: Work[] = [];
  public labList: Lab[] = [];
  public clinicList: Clinic[] = [];
  public user_type: string;
  public user_id: string;
  public relatedLabs: Lab[] = [];
  public relatedClinics: Clinic[] = [];
  public stateList: string[] = [];
  public selectedItem: Work;
  public user: Clinic | Lab;
  public isInitialLoad = true;

  private _filterList: FilterModel[] = [];
  private isSearchBoxFilter = false;
  private isLabTagFilter = false;
  private isStatusTagFilter = false;
  private isDropDownFilter = false;
  private isWorkItemFilter = false;

  public subscriber: Subscription;

  public sent = "sent";
  public delivered = "delivered";
  public read = "read";

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  dateType = 1;
  hasGesden: any;

  constructor(private _chatService: ChatService,
    private _messageService: MessageService,
    private _socketService: SocketService,
    public datepipe: DatePipe,
    private _router: Router,
    private _changeDetectionRef: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private _workService: WorkService,
    public commonUiService: CommonUiService) { }

  ngAfterViewChecked(): void {
    if (this.isInitialLoad && this.linkRefs.nativeElement.children.item(1) != null) {
      const collection = this.linkRefs.nativeElement.children;
      for (let i = 0; i < collection.length; i++) {
        const element = collection[i];
        if (element['localName'] === 'button') {
          if (element['name'] === 'pending') {
            element['value'] = 'clicked';
          }
        }
      }
      this.isInitialLoad = false;
    }
    this._changeDetectionRef.detectChanges();
  }

  ngOnInit() {
    if (!sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C') || !sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF')
      || !sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9')) { this._router.navigate(['/']); }
    this.user_type = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    this.user_id = sessionStorage.getItem('4CDC84B1E0607D91E3C7BBDE07EECC6A');

    let userData = JSON.parse(sessionStorage.getItem('2801636AC65D840F1735DC0833A61B69'));
    this.hasGesden = userData.hasGesden;

    this.selectedItem = new Work();
    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    if (data) {
      this.user = JSON.parse(data);

      this.dateType = 1;
      this.fromDate = this.getOneMonthBeforeCurrentDate();
      this.toDate = this.GetCurrentDate();
      if (this.user_type) {
        (this.user_type === 'lab') ? this.initClinicsByApplicationUser() :
          (this.user_type === 'clinic') ? this.initLabsByApplicationUser() : console.log('No user provided!');
      }
      this._filterList.push(new FilterModel('st', 'pending'));

      this._workService.workSubject.subscribe(async (res: any) => {
        if (res) {
          let _data = res["data"];
          this.selectedItem = res;
          this._chatService.getWorksByCenterId(this.user._id).subscribe(
            data => {
              this._workList = data;
              this.Filter();
              let id = _data["_id"];
              let work = this.workList.find(o => o._id == id);
              if (work) {
                work.patientName = _data.patientName;
                work.description = _data.description;
                this.loadChat(work);
                this.moveItemToFront(this.workList, id);
              }
            },
            err => console.error(err),
            () => {
              if (this.isInitialLoad) {
                this.setStatusButtonList();
              }
            });
        }
        else {
          this.isInitialLoad = false;
          this.initWorksByApplicationUser(this.user._id);
        }
      })

      // update message status
      this.updateMessageStatus(this.delivered);

      // init general resources
      this.initWorksByApplicationUser(this.user._id);
      this._socketService.getUnseenMessagesEmitter();
      this._socketService.getUnseenMessagesReciever().subscribe((list) => {
        this._unSeenMessageList = list;
        this.initWorksByApplicationUser(this.user._id);
      });

      // socket io
      this._socketService.sendMessageReciever().subscribe((message: Message) => {
        this._latestMessage = message;

      });

      // new lines
      this._socketService.getWorksReciever().subscribe((list) => {
        if (this._latestMessage && this.selectedItem) {
          if (this.user_type === 'clinic' && this._latestMessage.owner === 'clinic') {
            this._workList = list.filter((x, i, a) => x['clinicId'] === this.user['_id']);
          } else if (this.user_type === 'lab' && this._latestMessage.owner === 'lab') {
            this._workList = list.filter((x, i, a) => x['labId'] === this.user['_id']);
          }
          const index: number = this._workList.findIndex(e => e['_id'] === this._latestMessage.workId);
          const latestUpdatedWork: Work = this._workList[index];
          if (this.user_type === this._latestMessage.owner) {
            this.selectedItem = latestUpdatedWork;
          } else {
            if (this.selectedItem && this.selectedItem['_id'] === latestUpdatedWork['_id']) {
              this.selectedItem = latestUpdatedWork;
            }
          }
          this.workList = (this._filterList.length > 0) ? this.workList : this.sortWorksByLatestMessage(this._latestMessage, this._workList);
        }
      });

      this._socketService.updateWorkReciever().subscribe(async (work) => {
        const workIndex = this.workList.findIndex(a => a['_id'] == work['_id']);
        this.workList[workIndex] = work;

        const _workIndex = this._workList.findIndex(a => a['_id'] == work['_id']);
        this._workList[_workIndex] = work;

        if (workIndex >=0) {
          this.selectedItem = new Work();
          this.selectedItem = Object.assign(this.selectedItem, work);
          this._chatService.setSelectedWork(work);
          //this.setStatusButtonList();
        }
      });

      this._socketService.updateWorkStatusReciever().subscribe(async (work) => {
        this.isInitialLoad = false;
        await this.initWorksByApplicationUser(this.user._id);
        const workIndex = this.workList.findIndex(a => a['_id'] == work['_id']);
        this.workList[workIndex] = work;

        const _workIndex = this._workList.findIndex(a => a['_id'] == work['_id']);
        this._workList[_workIndex] = work;

        if (workIndex >=0) {
          this.selectedItem = new Work();
          this.selectedItem = Object.assign(this.selectedItem, work);
          this._chatService.setSelectedWork(work);
          //this.setStatusButtonList();
        }
      });

      // socket io to sync message status
      this._socketService.syncMessageStatusReceiver().subscribe((workId) => {
        if (this.selectedItem != null && this.selectedItem['_id'] != null && workId == this.selectedItem['_id']) {
          this.loadChat(this.selectedItem, true);
        }
      });
    }

    // eof new lines
    if (this.user_type) {
      // (this.user_type === 'lab') ? this.initClinicsByApplicationUser() :
      //   (this.user_type === 'clinic') ? this.initLabsByApplicationUser() : console.log('No user provided!');
    } else {
      return this._router.navigate(['/']);
    }
  }

  setStatusButtonList() {
    const stateList = this._workList.map(data => data.state);
    const uniqueStateList = stateList.filter((x, i, a) => x && a.indexOf(x) === i);
    this.stateList = [];
    this._filterList = [];
    uniqueStateList.forEach(item => {
      if (item == 'active' && this.isInitialLoad != true) {
        this._filterList.push(new FilterModel('st', 'active'));
      }
      if (item == 'pending') {
        this._filterList.push(new FilterModel('st', 'pending'));
      }
      if (item == 'closed' && this.isInitialLoad != true) {
        this._filterList.push(new FilterModel('st', 'closed'));
      }
      if (item == 'rejected' && this.isInitialLoad != true) {
        this._filterList.push(new FilterModel('st', 'rejected'));
      }
      if (item == 'finalized' && this.isInitialLoad != true) {
        this._filterList.push(new FilterModel('st', 'finalized'));
      }
    });
    uniqueStateList.forEach(item => {
      this.stateList.push(item);
    });
    this.Filter();
  }

  public mouseEnter(state: string, event: any): void {
    if (state && event) {
      if (state === 'active') {
        event.target.classList.add('c-active-hover');
      }
      if (state === 'closed') {
        event.target.classList.add('c-closed-hover');
      }
      if (state === 'pending') {
        event.target.classList.add('c-pending-hover');
      }
      if (state === 'rejected') {
        event.target.classList.add('c-rejected-hover');
      }
      if (state === 'finalized') {
        event.target.classList.add('c-finalized-hover');
      }
    }
  }

  public mouseLeave(state: string, event: any): void {
    if (state && event) {
      if (state === 'active') {
        event.target.classList.remove('c-active-hover');
      }
      if (state === 'closed') {
        event.target.classList.remove('c-closed-hover');
      }
      if (state === 'pending') {
        event.target.classList.remove('c-pending-hover');
      }
      if (state === 'rejected') {
        event.target.classList.remove('c-rejected-hover');
      }
      if (state === 'finalized') {
        event.target.classList.remove('c-finalized-hover');
      }
    }
  }


  private setStatusButtons(): void {
    this.isStatusTagFilter = true;
    this._filterList.push(new FilterModel('st', 'pending'));
    this._filterList.push(new FilterModel('st', 'active'));
    this.Filter();
  }

  /*
      regarding clinic view
  */

  private async initWorksByApplicationUser(centerId: string): Promise<void> {
    this._chatService.getWorksByCenterId(centerId).subscribe(
      data => {
        this._workList = data
        this.loadStateList();
        this.Filter();
      },
      err => console.error(err),
      () => {
        if (this.isInitialLoad) {
          this.setStatusButtonList();
          if (this.user_type) {
            (this.user_type === 'lab') ? this.initClinicsByApplicationUser() :
              (this.user_type === 'clinic') ? this.initLabsByApplicationUser() : console.log('No user provided!');
          }
        }
      });
  }

  private sortWorksByCreationDate(array: Work[]): Work[] {
    if (array.length > 1) {
      return array.reverse();
    }
    return array;
  }

  private sortWorksByLatestMessage(latestMessage: Message, array: Work[]) {
    if (latestMessage && array) {
      const index: number = array.findIndex(e => e['_id'] === latestMessage.workId);
      const latestUpdatedWork: Work = array[index];
      if (index !== -1) {
        array.splice(index, 1);
      }
      array = this.sortWorksByCreationDate(array);
      array.unshift(latestUpdatedWork);
      return array;
    }
  }

  private async initLabsByApplicationUser(): Promise<void> {
    this._chatService.getLabs().subscribe(
      data => {
        this.labList = data;

        this.filterLabsByWorkList();
      },
      err => console.error(err)
    );
  }

  private filterLabsByWorkList(){
    if (this._workList && this.labList) {
      const labIdList = new Set(this._workList.map(data => data.labId));
      this.relatedLabs = this.labList.filter(element => labIdList.has(element['_id']));
    }
  }

  private async initClinicsByApplicationUser(): Promise<void> {
    this._chatService.getClinics().subscribe(
      data => {
        this.clinicList = data;

        this.filterClinicsByWorkList();
      },
      err => console.error(err)
    );
  }

  private filterClinicsByWorkList(){
    if (this._workList && this.clinicList) {
      const clinicIdSet = new Set(this._workList.map(data => data.clinicId));
      this.relatedClinics = this.clinicList.filter(element => clinicIdSet.has(element['_id']));
    }
  }

  loadStateList() {
    const stateList = this._workList.map(data => data.state);
    const uniqueStateList = stateList.filter((x, i, a) => x && a.indexOf(x) === i);
    this.stateList = [];
    uniqueStateList.forEach(item => {
      this.stateList.push(item);
    });
  }

  /* Filters for clinic view */

  public OnClickLab(value: string, event?: any): void {
    if (value && event) {
      if (event.target.value === 'clicked') {
        event.target.value = '';
        event.target.classList.remove('btn-primary');
        this.isLabTagFilter = false;
        this.PopFromFilterList(this._filterList, new FilterModel('la', value));
      } else {
        event.target.value = 'clicked';
        event.target.classList.add('btn-primary');
        this.isLabTagFilter = true;
        this.PushIntoFilterList(this._filterList, new FilterModel('la', value));
      }
      this._filterList = this._filterList.filter(item => item.filterName !== 'dr');
      this.Filter();
    } else {
      console.log('No filters found!');
    }
  }

  private SetButtonBorders(event: any): void {
    if (event) {
      switch (event.target.id) {
        case 'active-status':
          event.target.classList.add('c-active-border');
          break;
        case 'pending-status':
          event.target.classList.add('c-pending-border');
          break;
        case 'closed-status':
          event.target.classList.add('c-closed-border');
          break;
        case 'rejected-status':
          event.target.classList.add('c-rejected-border');
          break;
        case 'finalized-status':
          event.target.classList.add('c-finalized-border');
          break;
        default:
          break;
      }
    }
  }

  private RemoveButtonBorders(event: any): void {
    if (event) {
      switch (event.target.id) {
        case 'active-status':
          event.target.classList.remove('c-active-border');
          break;
        case 'pending-status':
          event.target.classList.remove('c-pending-border');
          break;
        case 'closed-status':
          event.target.classList.remove('c-closed-border');
          break;
        case 'finalized-status':
          event.target.classList.remove('c-finalized-border');
          break;
        default:
          break;
      }
    }
  }

  public OnChangeStatus(value: string, event?: any): void {
    if (value && event) {
      if (event.target.value === 'clicked') {
        event.target.value = '';
        this.RemoveButtonBorders(event);
        if (this._filterList.filter((x, i, a) => x.filterName === 'st').length === 0) {
          this.isStatusTagFilter = false;
        }
        this.PopFromFilterList(this._filterList, new FilterModel('st', value));
      } else {
        event.target.value = 'clicked';
        this.SetButtonBorders(event);
        this.isStatusTagFilter = true;
        this.PushIntoFilterList(this._filterList, new FilterModel('st', value));
      }
      this.Filter();
    } else {
      console.log('No filters found!');
    }
  }

  private IsIncludedInFilterList(list: FilterModel[], filter: FilterModel): boolean {
    if (list.length > 0 && filter !== undefined) {
      if (filter.filterName === 'st' || filter.filterName === 'la' || filter.filterName === 'wi') {
        return list.filter(x => x.filterName === filter.filterName && x.filter === filter.filter).length > 0;
      }

      return list.filter(x => x.filterName === filter.filterName).length > 0;
    }
    return false;
  }

  private PushIntoFilterList(list: FilterModel[], filter: FilterModel): void {
    if (!this.IsIncludedInFilterList(list, filter)) {
      this._filterList.push(filter);
    }
  }

  private PopFromFilterList(list: FilterModel[], filter: FilterModel): void {
    if (this.IsIncludedInFilterList(list, filter)) {
      let index = -1;
      if (filter.filterName === 'st' || filter.filterName === 'la' || filter.filterName === 'wi') {
        index = this._filterList.findIndex(e => e.filterName === filter.filterName && e.filter === filter.filter);
      } else {
        index = this._filterList.findIndex(e => e.filterName === filter.filterName);
      }

      if (index !== -1) {
        this._filterList.splice(index, 1);
      }
    }
  }

  // search bar
  public onKey(value: string): void {
    if (value !== '') {
      this.isSearchBoxFilter = true;
      this.PopFromFilterList(this._filterList, new FilterModel('sb', value));
      this.PushIntoFilterList(this._filterList, new FilterModel('sb', value));
    } else {
      this.isSearchBoxFilter = false;
      this.PopFromFilterList(this._filterList, new FilterModel('sb', value));
    }
    this._filterList = this._filterList.filter(item => item.filterName !== 'la');
    this.Filter();
  }

  public OnWorkItemClick(value: string, event?: any) {
    if (value && event) {
      if (event.target.value === 'clicked') {
        event.target.value = '';
        if (value === 'c') {
          event.target.classList.remove('active-work-item-clinic');
        } else {
          event.target.classList.remove('active-work-item-lab');
        }
        this.isWorkItemFilter = false;
        this.PopFromFilterList(this._filterList, new FilterModel('wi', value));
      } else {
        event.target.value = 'clicked';
        if (value === 'c') {
          event.target.classList.add('active-work-item-clinic');
        } else {
          event.target.classList.add('active-work-item-lab');
        }
        this.isWorkItemFilter = true;
        this.PushIntoFilterList(this._filterList, new FilterModel('wi', value));
      }
      this.Filter();
    }
  }

  // Filters for lab view

  public OnClinicDropDownSelected(value, event?: any): void {
    this._filterList = this._filterList.filter(item => item.filterName !== 'dr');
    if (value !== '' && value !== 'all') {
      this.isDropDownFilter = true;
      this.PushIntoFilterList(this._filterList, new FilterModel('dr', value));
    }
    this._filterList = this._filterList.filter(item => item.filterName !== 'la');
    this.Filter();
  }

  public OnLabDropDownSelected(value, event?: any): void {
    this._filterList = this._filterList.filter(item => item.filterName !== 'la');
    if (value !== '' && value !== 'all') {
      this.isDropDownFilter = true;
      this.PushIntoFilterList(this._filterList, new FilterModel('la', value));
    }
    this._filterList = this._filterList.filter(item => item.filterName !== 'dr');
    this.Filter();
  }

  public Filter(): void {
    this.workList = [];
    const applyFilters = () => {
      this._workList.forEach(element => {
        element.convertedEntryDate = this.datepipe.transform(element.entryDate, 'dd-MM-yyyy');
      });

      if (this._filterList.length > 0) {
        let source: Work[] = [];
        source =  Object.assign(source, this._workList);

        const normalizeAndIncludes = (value: string, property: string) =>
          unidecode(property.toLowerCase()).includes(unidecode(value.toLowerCase())); // Compare lowercased values

        const filterByNormalizedValue = (value: string, properties: string[]) =>
          source.filter(x => properties.some(property => normalizeAndIncludes(value, x[property])));

        const filterByValue = (value: string, property: string) =>
          source.filter(x => x[property].toLowerCase() === value.toLowerCase()); // Compare lowercased values

        const filterByDateRange = (dateProperty: string) => {
          const fromDateObj = this.fromDate ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day) : null;
          const toDateObj = this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : null;

          return source.filter((item) => {
            if (item[dateProperty] == null || item[dateProperty] == '') {
              return false; // Skip items with null date
            }

            const date = new Date(item[dateProperty]);
            date.setHours(0, 0, 0, 0); // Set time to midnight

            if (fromDateObj && toDateObj) {
              return date >= fromDateObj && date <= toDateObj;
            } else if (fromDateObj) {
              return date >= fromDateObj;
            } else if (toDateObj) {
              return date <= toDateObj;
            }

            return true; // No date range specified, include all items
          });
        };

        if (this.user_type === 'clinic' || this.user_type === 'lab') {
          const sbFilter = this._filterList.find(f => f.filterName === 'sb')?.filter || '';
          const normalizedValue = unidecode(sbFilter.toLowerCase());

          // Filter directly from _workList without using source
          source = this._workList.filter(x =>
            filterByNormalizedValue(sbFilter, ['description', 'state', 'patientName', 'patientSurname', 'clinicName', 'labName', 'patientHistoryNumber']).includes(x) ||
            unidecode(x.convertedEntryDate).includes(normalizedValue)
          );
        }

        if (this._filterList.some(f => f.filterName === 'st')) {
          const statusFilterList = this._filterList.filter(f => f.filterName === 'st');
          source = source.filter(val => statusFilterList.some(item => item.filter.toLowerCase() === val['state'].toLowerCase())); // Compare lowercased values
        }

        if (this._filterList.some(f => f.filterName === 'la')) {
          const labList = this._filterList.filter(f => f.filterName === 'la');
          source = source.filter(val => labList.some(item => item.filter.toLowerCase() === val['labId'].toLowerCase())); // Compare lowercased values
        }

        if (this._filterList.some(f => f.filterName === 'dr')) {
          const value = (this._filterList.find(f => f.filterName === 'dr')).filter.toLowerCase();
          source = filterByValue(value, 'clinicId');
        }

        if (this._filterList.some(f => f.filterName === 'wi')) {
          const value = (this._filterList.find(f => f.filterName === 'wi')).filter.toLowerCase();
          source = filterByValue(value, 'workItemLocation');
        }

        if (this.dateType == 1) {
          source = filterByDateRange('entryDate');
        }

        if (this.dateType == 2) {
          source = filterByDateRange('deliveryDate');
        }

        this.workList =  Object.assign(this.workList, source);
      } else {
        this.workList =  Object.assign(this.workList, this._workList);
      }
    };

    applyFilters();
  }

  private GetClinicIdByName(clinicName: string) {
    if (clinicName) {
      if (this.clinicList.find((x, i, a) => x['name'].includes(clinicName))) {
        return (this.clinicList.find((x, i, a) => x['name'].includes(clinicName)))['_id'];
      }
    }
  }

  private GetLabIdByName(labName: string) {
    if (labName) {
      if (this.labList.find((x, i, a) => x['name'].includes(labName))) {
        return (this.labList.find((x, i, a) => x['name'].includes(labName)))['_id'];
      }
    }
  }

  public async CheckLabName(labId: string, value: string): Promise<boolean> {
    const labName = await this.getLabName(labId);
    return labName.toLowerCase().includes(value);
  }

  public async CheckClinicName(clinicId: string, value: string): Promise<boolean> {
    const clinicName = await this.getLabName(clinicId);
    return clinicName.toLowerCase().includes(value);
  }

  public loadChat(item: Work, isSync?: boolean) {
    if (item && item['_id'] != null) {
      this.selectedItem = new Work();
      this.selectedItem = Object.assign(this.selectedItem, item);
      this._chatService.setSelectedWork(item);
      if (this._unSeenMessageList) {
        const list: Message[] = this.filterMessagesByWork(this._unSeenMessageList, this.selectedItem['_id']);
        this.updateUnSeenMessages(list);
      }
      this._socketService.getUnseenMessagesEmitter();
      this._chatService.setAndPublishMessage(item, undefined, undefined);
      this._socketService.getAttachmentsByWorkIdEmitter(this.selectedItem['_id']);
      if (isSync != true) {
        this.updateMessageStatus(this.read);
      }
      this._chatService.setIsHome();
      this._chatService.setLatest(0);
      this._chatService.setOldest(0);
     this.initWorksByApplicationUser(this.user._id);
    } else {
      console.log('Selected work is not a valid work!');
    }
  }

  public getUnSeenCount(work: Work) {
    if (this._unSeenMessageList.length > 0) {
      switch (this.user_type) {
        case 'clinic':
          return (this._unSeenMessageList.filter((x, i, a) => x.workId === work['_id']
            && x.owner === 'lab' && x.readDateTime === '')).length;
        case 'lab':
          return (this._unSeenMessageList.filter((x, i, a) => x.workId === work['_id']
            && x.owner === 'clinic' && x.readDateTime === '')).length;
        default:
          console.log('no user provided!');
          return 0;
      }
    }
    return 0;
  }

  private filterMessagesByWork(list: Message[], workId: string): Message[] {
    if (list && workId) {
      return list.filter((x, i, a) => x.workId === workId);
    }
    return undefined;
  }

  // update un seen messages
  private async updateUnSeenMessages(list: any): Promise<void> {
    if (list) {
      list.forEach(element => {
        if (element.owner !== this.user_type) {
          element.readDateTime = new Date().toISOString();
          element.state = this.read;
          this._messageService.updateMessageById(element._id, element).subscribe(
            data => {
              const index: number = list.findIndex(e => e === element);
              if (index !== -1) {
                list.splice(index, 1);
              }
            },
            err => console.error(err),
            () => { });
        }
      });
    }
  }

  public getFirstTwoLetters(patientName: string) {
    if (patientName) {
      const arr = patientName.split(' ');
      if (arr.length > 1) {
        return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
      } else {
        return (arr[0].substring(0, 2)).toUpperCase();
      }
    }
  }

  public getClinicName(clinicId: string) {
    if (clinicId) {
      if ((this.clinicList.filter((x, i, a) => x['_id'] === clinicId)[0])) {
        return (this.clinicList.filter((x, i, a) => x['_id'] === clinicId)[0])['name'];
      }
    }
  }

  public getLabName(labId: string) {
    if (labId) {
      if ((this.labList.filter((x, i, a) => x['_id'] === labId)[0])) {
        return (this.labList.filter((x, i, a) => x['_id'] === labId)[0])['name'];
      }
    }
  }

  public getTimeDifference(start: string) {
    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    const user = JSON.parse(data);

    let timeDif = '';
    const startDate = new Date(this._chatService.getConvertedTime(start));

    const endDate = new Date();
    const seconds = ((endDate.getTime() - startDate.getTime()) / 1000);

    if (seconds >= 60) {
      const toMins = (seconds / 60);
      if (user.language === 'en') {
        timeDif = parseInt(toMins.toString()) + ((toMins > 2) ? ' mins ago' : ' min ago');
      } else {
        timeDif = 'Hace ' + parseInt(toMins.toString()) + ' min.';
      }

      if (toMins >= 60) {
        const toHours = (toMins / 60);
        if (user.language === 'en') {
          timeDif = parseInt(toHours.toString()) + ((toHours > 2) ? ' hours ago' : ' hour ago');
        } else {
          timeDif = 'Hace ' + parseInt(toHours.toString()) + ' horas.';
        }

        if (toHours >= 24) {
          const toDays = (toHours / 24);
          if (user.language === 'en') {
            timeDif = parseInt(toDays.toString()) + ((toDays > 2) ? ' days ago' : ' day ago');
          } else {
            timeDif = 'Hace ' + parseInt(toDays.toString()) + ' días';
          }

          if (toDays >= 30) {
            const toMonths = (toDays / 30);
            if (user.language === 'en') {
              timeDif = parseInt(toMonths.toString()) + ((toMonths > 2) ? ' months ago' : ' month ago');
            } else {
              timeDif = 'Hace ' + parseInt(toMonths.toString()) + ' meses';
            }

            if (toMonths >= 12) {
              const toYears = (toMonths / 12);
              if (user.language === 'en') {
                timeDif = parseInt(toYears.toString()) + ((toYears > 2) ? ' years ago' : ' year ago');
              } else {
                timeDif = 'Hace ' + parseInt(toYears.toString()) + ' años';
              }
            }
          }
        } else {
          if (user.language === 'en') {
            timeDif = 'Today';
          } else {
            timeDif = 'Hoy';
          }
        }
      }
    } else {
      if (seconds === 0) {
        if (user.language === 'en') {
          timeDif = '1 sec ago';
        } else {
          timeDif = 'Hace 1 segundo';
        }
      } else {
        if (seconds < 0) {
          if (user.language === 'en') {
            timeDif = '0 secs ago';
          } else {
            timeDif = 'Hace 0 segundo';
          }
        } else {
          if (user.language === 'en') {
            timeDif = parseInt(seconds.toString()) + ((seconds > 2) ? ' secs ago' : ' sec ago');
          } else {
            timeDif = 'Hace' + parseInt(seconds.toString()) + ' segundo';
          }
        }
      }
    }
    return timeDif;
  }

  public changeDateFormat(date: string) {
    return new Date(date).toDateString();
  }

  private getRevertedDate(value: string): any {
    if (value) {
      const date = new Date(value).toLocaleDateString();
      if (date !== undefined) {
        const dateString: string = date.substring(0, 10);
        if (dateString.length > 0) {
          const arr: string[] = dateString.split('/');
          const dateFormat = new DateFormat(Number(arr[1]), Number(arr[0]), Number(arr[2]));
          const dateObject = this.convertToValidDate(dateFormat);
          return dateObject[0] + '-' + dateObject[1] + '-' + dateObject[2];
        }
      }
    }
    return false;
  }

  private convertToValidDate(date: DateFormat): any {
    let day = ''; let month = ''; let year = '';
    if (date) {
      day = date.day.toString(); month = date.month.toString(); year = date.year.toString();
      if (date.day > 0 && date.day < 10) {
        day = '0' + date.day;
      }
      if (date.month > 0 && date.month < 10) {
        month = '0' + date.month;
      }
      return [day, month, year];
    }
  }

  // toggleLeftBar() {
  //   const wrapper = document.getElementById('wrapper');
  //   if (wrapper) {
  //     console.log(wrapper.classList);
  //     wrapper.classList.toggle('left-bar-enabled');
  //   }
  // }

  public onOpenWorkModal() {
    const modalRef = this.modalService
      .open(AddWorkComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'add-work-popup-wrapper',
        backdrop: 'static',
        keyboard: false
      });
    modalRef.componentInstance.work = new Work();
  }

  // update message status
  private async updateMessageStatus(status: string): Promise<void> {
    const dateTime = new Date().toISOString();
    this._messageService.updateMessageStatus(this.user_id, dateTime, status, this.selectedItem != null ? this.selectedItem['_id'] : null).subscribe(
      data => {
        this._socketService.syncMessageStatusEmitter(this.selectedItem != null ? this.selectedItem['_id'] : null);
      },
      err => console.error(err),
      () => { });
  }

  public OnDateTypeSelected(value, event?: any): void {
    if (value !== '') {
      this._filterList = this._filterList.filter(item => item.filterName !== 'la');
      this._filterList = this._filterList.filter(item => item.filterName !== 'dr');
      this.Filter();
    }
  }

  GetCurrentDate() {
    return this.commonUiService.ConvertToPickerDateFormat(moment().format('YYYY-MM-DD'));
  }

  getOneMonthBeforeCurrentDate(){
    return this.commonUiService.ConvertToPickerDateFormat(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  }

  public FilterByDate() {
    this._filterList = this._filterList.filter(item => item.filterName !== 'la');
    this._filterList = this._filterList.filter(item => item.filterName !== 'dr');
    this.Filter();
  }

  // Find the index of an object in the array by its _id property
  private findIndexById(array: any[], targetId: number): number {
    return array.findIndex(obj => obj._id == targetId);
  }

  // Move an object to the 0th position in the array by _id
  private moveItemToFront(array: any[], targetId: number): void {
    const index = this.findIndexById(array, targetId);
    if (index !== -1) {
      const [item] = array.splice(index, 1); // Remove the item from its position
      array.unshift(item); // Add the item to the front
    }
  }

  openDentboxWorkList() {
    // Open the work request list component in a new tab
    window.open(this._router.serializeUrl(this._router.createUrlTree(['/dentbox-work-request-list'])), '_blank');
  }
}

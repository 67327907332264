import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WorkService } from '../../service/work.service';
import { ChatService } from '../../service/chat.service';
import { SocketService } from '../../service/sockets.service';
import { Work } from '../../model/work';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'work-chat',
  templateUrl: './work-chat.component.html',
  styleUrls: ['./work-chat.component.scss']
})
export class WorkChatComponent implements OnInit {

  private _count = 0;
  public providedWork: Work;
  isOpenFromDentbox: boolean = false;

  constructor(
    private _toaster: ToastrService,
    private _workService: WorkService,
    private _chatService: ChatService,
    private _socketService: SocketService,
    private route: ActivatedRoute) { }

  async ngOnInit() {

    $('.right-bar-toggle').on('click', function (e) {
      $('#wrapper').toggleClass('right-bar-enabled');
    });


    if (sessionStorage.getItem('5EC358E33FC855F0268D055B457B1D68')) {
      const workGuid = sessionStorage.getItem('5EC358E33FC855F0268D055B457B1D68');
      const work: Work = await this._workService.getWorkByGuid(workGuid).toPromise();
      if (work) {
        if (work._id) {
          this.providedWork = work;
          this._chatService.setSelectedWork(this.providedWork);
          this._chatService.setAndPublishMessage(this.providedWork, undefined, undefined);
          this._socketService.getAttachmentsByWorkIdEmitter(this.providedWork['_id']);
          this._chatService.setIsHome();
          this._chatService.setLatest(0);
          this._chatService.setOldest(0);
        } else {
          if (this._count === 0) { this._count++; location.reload(); } else { this._toaster.error('Invalid work Id!', 'INVALID WORK ID!'); }
        }
      }
    }

    this.route.queryParams.subscribe(params => {
      this.isOpenFromDentbox = params['isOpenFromDentbox'] === 'true';
    });
  }
}
